import { listMotionItem, listMotionContainer } from "app/motion/listMotion";
import { Path } from "app/Path";
import classNames from "classnames";
import { Spinner } from "flowbite-react";
import { motion } from "framer-motion";
import { capitalize } from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import { useMechanicFavoriteLists } from "./useMechanicFavoriteLists";
import { Footer } from "app/components/footer/footer";
import MechanicPlaceholder from "app/assets/images/mechanicPlaceholder.png";

export interface MechanicFavoriteListProps {}

export const MechanicFavoriteLists: React.FC<MechanicFavoriteListProps> = (
  props
) => {
  const {} = props;
  const { favoriteLists, loading } = useMechanicFavoriteLists();

  if (loading) {
    return (
      <div className="h-screen">
        <Spinner size={"lg"} className="absolute top-1/2 left-1/2" />
      </div>
    );
  }

  if (favoriteLists.length === 0) {
    return (
      <div className="flex justify-center m-auto absolute top-1/2 left-1/2 -translate-x-1/2 max-w-2xl">
        <p className="font-medium">
          You don't have any favorites search for some of your favorites{" "}
          <Link to={Path.HOME} className="inline-flex">
            <p className="text-blue-600 font-medium">here</p>
          </Link>
        </p>
      </div>
    );
  }

  return (
    <>
      <section className="flex flex-col w-full mt-4 max-w-5xl m-auto p-6">
        <p className="mb-6 text-xl font-medium">Favorites</p>
        <motion.ul
          className="container grid gap-2 grid-cols-1 sm:grid-cols-2 md:gap-8 "
          variants={listMotionContainer}
          initial="hidden"
          animate="visible"
        >
          {favoriteLists.map((favoriteList: any, index: number) => (
            <motion.li key={index} variants={listMotionItem}>
              <Link
                to={`${Path.MECHANICS_FAVORITES}/${favoriteList.id}`}
                className="flex items-center p-4 bg-white border border-gray-200 rounded shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
              >
                <div
                  className={classNames("grid w-12 h-12 rounded mr-4", {
                    [`grid-cols-2`]: favoriteList.mechanicAccounts.length > 1,
                    [`grid-rows-2`]: favoriteList.mechanicAccounts.length > 2,
                  })}
                >
                  {favoriteList.mechanicAccounts
                    .slice(0, 4)
                    .map((mechanic: any) => (
                      <img
                        className={"w-6 h-6"}
                        src={mechanic.logo || MechanicPlaceholder}
                        alt={`${mechanic.name} logo`}
                      />
                    ))}
                </div>
                <p className="leading-relaxed text-base font-medium dark:text-gray-400">
                  {capitalize(favoriteList.name)}
                </p>
              </Link>
            </motion.li>
          ))}
        </motion.ul>
      </section>
      <Footer />
    </>
  );
};
