import { Path } from "app/Path";
import { selectUser, userLogout } from "app/redux/usersSlice";
import { toast, ToastType } from "app/utils/toast";
import { useQuery } from "app/utils/useQuery";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useNavbar = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const query = useQuery();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const openAuthModal = () => {
    query.append("auth", "login");
    navigate({
      pathname: window.location.pathname,
      search: query.toString(),
    });
  };

  const handleLogout = async () => {
    try {
      await dispatch(userLogout({}) as any).unwrap();
      toast(ToastType.SUCCESS, "You have successfully logged out.");
      navigate(Path.HOME);
      setDrawerIsOpen(false);
    } catch (error: any) {
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong with your logout."
      );
    }
  };

  const handleMobileLoginClick = () => {
    setDrawerIsOpen(false);
    openAuthModal();
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > prevScrollPos) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  return {
    user,
    openAuthModal,
    drawerIsOpen,
    setDrawerIsOpen,
    handleLogout,
    handleMobileLoginClick,
    visible,
  };
};
