import { useEffect } from "react";
import Cookies from "js-cookie";
import { batch, useDispatch, useSelector } from "react-redux";
import { getMechanicFavoriteLists } from "./redux/mechanicFavoritesListSlice";
import { getMechanicFavorites } from "./redux/mechanicFavoritesSlice";
import { userFromCookie } from "./redux/usersSlice";
import { cookieTokenName } from "./requests/axiosInstance";
import { toast, ToastType } from "./utils/toast";
import { getIpData } from "./redux/ipDataSlice";
import { getMechanicAreas } from "./redux/mechanicAreasSlice";

export const useApp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        dispatch(getIpData() as any).unwrap();
        dispatch(getMechanicAreas() as any).unwrap();

        if (!Cookies.get(cookieTokenName!)) return;
        batch(() => {
          dispatch(userFromCookie({}) as any).unwrap();
          dispatch(getMechanicFavorites({}) as any).unwrap();
          dispatch(getMechanicFavoriteLists({}) as any).unwrap();
        });
      } catch (error: any) {
        toast(ToastType.ERROR, error.data.error || "Something went wrong");
      }
    })();
  }, []);

  return {};
};
