export const medianCoordinates = (latLngsArr: any) => {
  var latXTotal = 0;
  var latYTotal = 0;
  var lonDegreesTotal = 0;

  var currentLatLong;
  for (var i = 0; (currentLatLong = latLngsArr[i]); i++) {
    var latDegrees = Number(currentLatLong.lat);
    var lonDegrees = Number(currentLatLong.lng);

    var latRadians = (Math.PI * latDegrees) / 180;
    latXTotal += Math.cos(latRadians);
    latYTotal += Math.sin(latRadians);

    lonDegreesTotal += lonDegrees;
  }

  var finalLatRadians = Math.atan2(latYTotal, latXTotal);
  var finalLatDegrees = (finalLatRadians * 180) / Math.PI;
  var finalLonDegrees = lonDegreesTotal / latLngsArr.length;

  return {
    lat: finalLatDegrees,
    lng: finalLonDegrees,
  };
};
