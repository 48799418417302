import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Path } from "app/Path";
import { ProtectedRoute } from "./protectedRoutes";
import { MechanicShow } from "app/mechanic/show/mechanicShow";
import { MechanicList } from "app/mechanic/mechanicList/mechanicList";
import { Home } from "app/home/home";
import { MechanicFavoriteLists } from "app/mechanic/mechanicFavoriteLists/mechanicFavoriteLists";
import { MehanicFavorites } from "app/mechanic/mechanicFavorites/mechanicFavorites";
import { User } from "app/user/user";
import { NotFound } from "app/notFound/notFound";
import { BlogShow } from "app/blog/show/blogShow";
import { BlogIndex } from "app/blog/index/blogIndex";
import { Explore } from "app/explore/explore";
import { TermsAndConditions } from "app/termsAndConditions/termsAndConditions";
import { PrivacyPolicy } from "app/privacyPolicy/privacyPolicy";
import { MechanicList as BoatServiceMechanicList } from "app/boatServices/mechanicList/mechanicList";

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path={Path.HOME} element={<Home />} />
      <Route path={Path.MECHANICS} element={<MechanicList />} />
      <Route
        path={`${Path.MECHANICS}/:country/:region/:name/:id`}
        element={<MechanicShow />}
      />
      <Route path={Path.BLOG} element={<BlogIndex />} />
      <Route path={`${Path.BLOG}/:slug`} element={<BlogShow />} />
      <Route path={Path.EXPLORE} element={<Explore />} />
      <Route
        path={`${Path.BOATSERVICES}/:service`}
        element={<BoatServiceMechanicList />}
      />
      <Route path={Path.TAC} element={<TermsAndConditions />} />
      <Route path={Path.PRIVACY} element={<PrivacyPolicy />} />
      <Route
        path={Path.SETTINGS}
        element={
          <ProtectedRoute>
            <User />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${Path.MECHANICS_FAVORITES}/:id`}
        element={
          <ProtectedRoute>
            <MehanicFavorites />
          </ProtectedRoute>
        }
      />
      <Route
        path={Path.MECHANICS_FAVORITES}
        element={
          <ProtectedRoute>
            <MechanicFavoriteLists />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
