import { useEffect, useState } from "react";
import {
  getMechanicFavoriteLists,
  selectMechanicFavoritesList,
} from "app/redux/mechanicFavoritesListSlice";
import { toast, ToastType } from "app/utils/toast";
import { useDispatch, useSelector } from "react-redux";

export const useMechanicFavoriteLists = () => {
  const dispatch = useDispatch();
  const { favoriteLists } = useSelector(selectMechanicFavoritesList);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (favoriteLists.length > 0) return;
      try {
        setLoading(true);
        await dispatch(getMechanicFavoriteLists({}) as any).unwrap();
      } catch (error: any) {
        toast(
          ToastType.ERROR,
          error.data.error ||
            "Something went wrong getting your favorite mechanics."
        );
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    favoriteLists,
    loading,
  };
};
