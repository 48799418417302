import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import http from "app/requests/axiosInstance";

// Define the initial state using that type
const initialState = null as any;

export const getMechanicAreas = createAsyncThunk(
  "mechanics/getMechanicAreas",
  async (_, thunkAPI: any) => {
    const ipData = await thunkAPI.getState().ipData;
    try {
      const { data } = await http.get(
        `/v1/mechanic_areas?latitude=${ipData?.latitude}&longitude=${ipData?.longitude}`
      );
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const mechanicAreasSlice = createSlice({
  name: "mechanicAreas",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getMechanicAreas.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
  },
});

export const selectMechanicAreas = (state: RootState) => state.mechanicAreas;

export default mechanicAreasSlice.reducer;
