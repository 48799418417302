import * as React from "react";
import { Avatar, Card, TextInput } from "flowbite-react";
import { useUser } from "./useUser";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { Footer } from "app/components/footer/footer";

<CloudArrowUpIcon className="h-6 w-6 text-gray-500" />;

export interface UserProps {}

export const User: React.FC<UserProps> = (props) => {
  const {} = props;
  const {
    user: { firstName, lastName, email, avatar },
    onAvatarSubmit,
    onGeneralInformationSubmit,
    onPasswordChangeSubmit,
  } = useUser();

  return (
    <>
      <div className="flex flex-col gap-4 mt-8 w-full max-w-2xl m-auto sm:flex-row">
        <form
          onSubmit={onAvatarSubmit}
          className="w-full flex flex-col h-fit rounded gap-4 bg-white border p-4 sm:max-w-sm"
        >
          <div className="mb-2">
            <Avatar img={avatar} size="xl" className="!justify-start" />
          </div>
          <p className="text-2xl font-bold">
            {firstName} {lastName}
          </p>
          <label htmlFor="avatar" className="btn-blue cursor-pointer">
            <CloudArrowUpIcon className="h-6 w-6 text-white mr-2" />
            Change picture
          </label>
          <input
            id="avatar"
            name="file"
            className="hidden"
            type="file"
            accept="*"
            title="Change picture"
            onChange={onAvatarSubmit}
          />
        </form>
        <div className="flex flex-col gap-4 max-w-2xl w-full">
          <form
            onSubmit={onGeneralInformationSubmit}
            className="w-full flex flex-col h-fit rounded gap-4 bg-white border p-4"
          >
            <div>
              <h1 className="text-xl font-bold">General Information</h1>
              <div className="mb-2 block">
                <label className="text-sm font-medium">First Name</label>
              </div>
              <TextInput
                name="firstName"
                placeholder="Jane"
                required={true}
                defaultValue={firstName}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <label className="text-sm font-medium">Last Name</label>
              </div>
              <TextInput
                name="lastName"
                placeholder="Doe"
                defaultValue={lastName}
                required={true}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <label className="text-sm font-medium">Email</label>
              </div>
              <TextInput
                name="email"
                placeholder="name@company.com"
                required={true}
                defaultValue={email}
              />
            </div>
            <button type="submit" className="btn-blue bg-blue-600">
              Save all
            </button>
          </form>
          <form
            onSubmit={onPasswordChangeSubmit}
            className="w-full flex flex-col h-fit rounded gap-4 bg-white border p-4"
          >
            <div>
              <h1 className="text-xl font-bold">Password Information</h1>
              <div className="mb-2 block">
                <label className="text-sm font-medium">Current password</label>
              </div>
              <TextInput
                name="currentPassword"
                placeholder="Enter your current password"
                required={true}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <label className="text-sm font-medium">New password</label>
              </div>
              <TextInput
                name="newPassword"
                placeholder="Enter your new password"
                required={true}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <label className="text-sm font-medium">
                  Confirm new password
                </label>
              </div>
              <TextInput
                name="passwordConfirmation"
                placeholder="Confirm your new password"
                required={true}
              />
            </div>
            <button type="submit" className="btn-blue bg-blue-600">
              Save all
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};
