import * as React from "react";

export interface TermsAndConditionsProps {}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = (
  props
) => {
  const {} = props;
  return (
    <div className="mt-8 px-4 max-w-4xl m-auto">
      <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>
      <p className="mb-4">
        Welcome to FindMyBoatMechanic.com. By using our website and services,
        you agree to comply with and be bound by the following terms and
        conditions.
      </p>
      <ol className="flex flex-col gap-4 mb-4">
        <li>
          <span className="font-bold">Acceptance of Terms:</span> These terms
          and conditions govern your use of our website; by using our website,
          you accept these terms and conditions in full. If you disagree with
          these terms and conditions or any part of these terms and conditions,
          you must not use our website.
        </li>
        <li>
          <span className="font-bold">Services:</span> FindMyBoatMechanic.com is
          a directory service that allows users to find boat mechanics in their
          local area. We also provide informative blogs about boat maintenance.
          We do not sell any products or services.
        </li>
        <li>
          <span className="font-bold">Accuracy of Information:</span> While we
          strive to keep the information on our website up-to-date and correct,
          we make no warranties or representations of any kind about the
          completeness, accuracy, reliability, suitability, or availability of
          any information, products, services, or related graphics contained on
          the website.
        </li>
        <li>
          <span className="font-bold">Claim Business:</span> If you are a boat
          mechanic and find your business listed on our website, you can claim
          your business by clicking on the "Your business? Claim it for
          additional features" button. We will then work with you to ensure the
          information listed is accurate and up-to-date and provide more
          features.
        </li>
        <li>
          <span className="font-bold">Intellectual Property:</span> The content,
          organization, graphics, design, and other matters related to the site
          are protected under applicable copyrights and other proprietary laws,
          including but not limited to intellectual property laws. The copying,
          reproduction, use, modification or publication you of any such matters
          or any part of the site is strictly prohibited, without our express
          prior written permission.
        </li>
        <li>
          <span className="font-bold">Changes to Terms: </span> We reserve the
          right to modify these terms and conditions at any time, and such
          modifications shall be effective immediately upon posting of the
          modified terms and conditions on our website. You agree to review
          these terms and conditions periodically to be aware of such
          modifications and your continued access or use of the site shall be
          deemed your conclusive acceptance of the modified terms and
          conditions.
        </li>
      </ol>
      <p>
        <span className="font-bold">Contact:</span> If you have any questions
        about these terms and conditions, please contact us at{" "}
        <a
          className=" text-blue-500 underline hover:text-blue-700"
          href="mailto:al@tacit.ventures?subject=Inquiry about Find my boat mechanic Terms and Conditions"
        >
          cody@tacit.ventures
        </a>
        .
      </p>
    </div>
  );
};
