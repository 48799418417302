import { Avatar, Button, Modal, TextInput } from "flowbite-react";
import * as React from "react";
import { useFavoritesModal } from "./useFavoritesModal";
import { PlusIcon } from "@heroicons/react/24/outline";
import { capitalize } from "lodash";

export interface FavoritesModalProps {
  isOpen: boolean;
  toggleOpen: (show: boolean) => void;
  handleFavoriteListClick: (favoriteList: any) => void;
  onFavoriteWithNewList: (event: any) => void;
}

export const FavoritesModal: React.FC<FavoritesModalProps> = (props) => {
  const { isOpen, toggleOpen, handleFavoriteListClick, onFavoriteWithNewList } =
    props;
  const { favoriteLists, showFavoritePage, setShowFavoritePage } =
    useFavoritesModal();

  return (
    <React.Fragment>
      <Modal
        dismissible={true}
        show={isOpen}
        size="md"
        onClose={() => {
          toggleOpen(false);
          setShowFavoritePage(false);
        }}
        className="!h-full"
      >
        <Modal.Body>
          {showFavoritePage ? (
            <>
              <div className="flex relative items-center justify-between w-full">
                <p className="leading-relaxed text-center w-full text-base font-medium pb-8">
                  Name this list
                </p>
                <Modal.Header className="absolute right-0 top-0 !p-0 -mt-1 !border-0" />
              </div>
              <div className="flex flex-col relative justify-between w-full">
                <form
                  onSubmit={(event: any) => {
                    onFavoriteWithNewList(event);
                    setShowFavoritePage(false);
                  }}
                >
                  <TextInput className="w-full" name="newListName" required />
                  <p className="text-gray-500 text-sm pb-10">
                    50 characters maximum
                  </p>
                  <Button type="submit" className="btn-blue w-full">
                    Create
                  </Button>
                </form>
              </div>
            </>
          ) : (
            <>
              <div className="flex relative items-center justify-between w-full">
                <p className="leading-relaxed text-center w-full text-base font-medium pb-8">
                  Your favorites
                </p>
                <Modal.Header className="absolute right-0 top-0 !p-0 -mt-1 !border-0" />
              </div>
              <div className="space-y-6">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    setShowFavoritePage(true);
                  }}
                >
                  <div className="p-2 border rounded mr-3">
                    <PlusIcon className="h-6 w-6 text-black-500" />
                  </div>
                  <div className="font-medium">Create new list</div>
                </div>
                {favoriteLists.map((favoriteList: any) => (
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => handleFavoriteListClick(favoriteList)}
                  >
                    <div className="grid grid-cols-2 grid-rows-2 rounded mr-4">
                      {[0, 1, 2, 3].map((index: any) =>
                        favoriteList.mechanicAccounts[index]?.logo ? (
                          <img
                            className="w-6 h-6"
                            src={favoriteList.mechanicAccounts[index].logo}
                          />
                        ) : (
                          <Avatar
                            size="xs"
                            placeholderInitials={
                              favoriteList.mechanicAccounts[index]?.name
                            }
                          />
                        )
                      )}
                    </div>
                    <div className="flex w-full">
                      <p className="font-medium">
                        {capitalize(favoriteList.name)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
