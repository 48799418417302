import * as React from "react";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react"; // Provider imports 'rollbar'

export interface ProtectedRouteProps {
  children?: any;
}

export const Rollbar: React.FC<ProtectedRouteProps> = (props) => {
  const { children } = props;
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN!,
    environment: "production",
  };
  return process.env.NODE_ENV === "development" ? (
    children
  ) : (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </RollbarProvider>
  );
};
