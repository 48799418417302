import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearchedMechanics,
  selectSearchMechanics,
} from "app/redux/searchMechanicsSlice";
import { selectMechanicFavorites } from "app/redux/mechanicFavoritesSlice";
import { medianCoordinates } from "app/utils/medianCoordinates";
import { useMap } from "react-map-gl";
import { AppDispatch } from "app/store";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Path } from "app/Path";
import { ToastType, toast } from "app/utils/toast";
import { useQuery } from "app/utils/useQuery";

export interface OriginalMapSettings {
  lat: number;
  lng: number;
  zoom: number;
}

export const useMechanicMap = ({ lat, lng, zoom = 10 }: any) => {
  const searchedMechanics = useSelector(selectSearchMechanics).mechanicAccounts;
  const [mapProps, setMapProps] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const query = useQuery();
  const dragParam = query.get("drag");
  const dispatch = useDispatch<AppDispatch>();
  const [selectedMarkerId, setSelectedMarkerId] = useState<any>(null);
  const markers = searchedMechanics.map((mechanicAccount: any) => ({
    entityType: "mechanic",
    mechanicAccount,
  }));
  const favorites = useSelector(selectMechanicFavorites);
  const getMedianCoordinatesOfMechanics = () =>
    medianCoordinates(
      searchedMechanics.map((mechanicAccount: any) => ({
        lat: mechanicAccount.addresses[0].latitude,
        lng: mechanicAccount.addresses[0].longitude,
      }))
    );

  const onDragEnd = async ({ viewState }: any) => {
    try {
      await dispatch(
        getSearchedMechanics({
          searchQuery: `${viewState.latitude},${viewState.longitude}`,
          searchType: "coordinates",
        })
      ).unwrap();
    } catch (error: any) {
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong while searching mechanics"
      );
    } finally {
      navigate(
        `${Path.MECHANICS}?${createSearchParams({
          lat: viewState.latitude,
          lng: viewState.longitude,
          drag: "true",
        })}`
      );
      document.getElementById("mechanicList")?.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (searchedMechanics.length === 0) {
        setMapProps({
          initialViewState: {
            longitude: lng || 0,
            latitude: lat || 0,
            zoom: zoom || 0,
          },
          // style: { width: 600, height: 400 },
          mapStyle: "mapbox://styles/mapbox/streets-v12",
        });
        setLoading(false);
        return;
      }
      const centerCoordinates = getMedianCoordinatesOfMechanics();
      setMapProps({
        initialViewState: {
          longitude: centerCoordinates.lng,
          latitude: centerCoordinates.lat,
          zoom: zoom,
        },
        // style: { width: 600, height: 400 },
        mapStyle: "mapbox://styles/mapbox/streets-v12",
      });
      setLoading(false);
    })();
  }, [searchedMechanics]);

  const MapChangeListener = () => {
    const { current: map } = useMap();
    const centerCoordinates = getMedianCoordinatesOfMechanics();

    useEffect(() => {
      map!.flyTo({
        center:
          dragParam === "true"
            ? [lng, lat]
            : [centerCoordinates.lng, centerCoordinates.lat],
        zoom: dragParam === "true" ? map?.getZoom() : zoom,
      });
    }, [lat, lng]);
    return null;
  };

  return {
    mapProps,
    loading,
    markers,
    selectedMarkerId,
    setSelectedMarkerId,
    favorites,
    getMedianCoordinatesOfMechanics,
    MapChangeListener,
    onDragEnd,
  };
};
