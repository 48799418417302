import * as React from "react";
import { ClockIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { ListItem } from "../listItem";
import { map, some, compact, includes } from "lodash";
import classNames from "classnames";

export interface HoursOfOperationProps {
  hoursOfOperation?: any;
}

export const HoursOfOperation: React.FC<HoursOfOperationProps> = (props) => {
  const { hoursOfOperation } = props;

  const formatOpenCloseHours = (opens: any, closes: any) => {
    if (hoursOfOperation.unknownHours)
      return <p className="text-gray-500">Unknown</p>;
    if (!opens || !closes) return <p className="text-red-500">Closed</p>;
    return (
      <p className="flex w-40 justify-between pr-2">
        <p>{format(new Date(opens.split(".")[0]), "p")}</p>-
        <p>{format(new Date(closes.split(".")[0]), "p")}</p>
      </p>
    );
  };

  return (
    <div className="flex center">
      <ClockIcon
        className={classNames("h-5 w-5 mr-5 text-gray-900", {
          "mr-0": !hoursOfOperation,
        })}
      />
      {!hoursOfOperation ? (
        <ListItem
          customContent={"No hours of operations yet"}
          removeListGap
          iconContentGap="pr-0"
          hideIcon
        />
      ) : (
        <ul className="flex flex-col gap-2">
          <li className="flex w-full justify-between">
            <p className="mr-4">Monday</p>
            <div className="flex justify-start w-40">
              {formatOpenCloseHours(
                hoursOfOperation.mondayOpensAt,
                hoursOfOperation.mondayClosesAt
              )}
            </div>
          </li>
          <li className="flex w-full justify-between">
            <p className="mr-4">Tuesday</p>
            <div className="flex justify-start w-40">
              {formatOpenCloseHours(
                hoursOfOperation.tuesdayOpensAt,
                hoursOfOperation.tuesdayClosesAt
              )}
            </div>
          </li>
          <li className="flex w-full justify-between">
            <p className="mr-4">Wednesday</p>
            <div className="flex justify-start w-40">
              {formatOpenCloseHours(
                hoursOfOperation.wednesdayOpensAt,
                hoursOfOperation.wednesdayClosesAt
              )}
            </div>
          </li>
          <li className="flex w-full justify-between">
            <p className="mr-4">Thursday</p>
            <div className="flex justify-start w-40">
              {formatOpenCloseHours(
                hoursOfOperation.thursdayOpensAt,
                hoursOfOperation.thursdayClosesAt
              )}
            </div>
          </li>
          <li className="flex w-full justify-between">
            <p className="mr-4">Friday</p>
            <div className="flex justify-start w-40">
              {formatOpenCloseHours(
                hoursOfOperation.fridayOpensAt,
                hoursOfOperation.fridayClosesAt
              )}
            </div>
          </li>
          <li className="flex w-full justify-between">
            <p className="mr-4">Saturday</p>
            <div className="flex justify-start w-40">
              {formatOpenCloseHours(
                hoursOfOperation.saturdayOpensAt,
                hoursOfOperation.saturdayClosesAt
              )}
            </div>
          </li>
          <li className="flex w-full justify-between">
            <p className="mr-4">Sunday</p>
            <div className="flex justify-start w-40">
              {formatOpenCloseHours(
                hoursOfOperation.sundayOpensAt,
                hoursOfOperation.sundayClosesAt
              )}
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};
