import { listMotionItem, listMotionContainer } from "app/motion/listMotion";
import { Path } from "app/Path";
import { Pagination, Spinner } from "flowbite-react";
import { motion } from "framer-motion";
import { capitalize, compact, kebabCase, startCase } from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import { Footer } from "app/components/footer/footer";
import { useMechanicList } from "./useMechanicList";
import { titleize } from "app/utils/titleize";
import MechanicPlaceholder from "app/assets/images/mechanicPlaceholder.png";
import { Helmet } from "react-helmet";

export interface MechanicList {}

export const MechanicList: React.FC<MechanicList> = () => {
  const { mechanicAccounts, pagy, loading, service, onPagechange, ipData } =
    useMechanicList();

  if (loading) {
    return (
      <div className="h-screen">
        <Spinner size={"lg"} className="absolute top-1/2 left-1/2" />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Find My Boat Mechanic | List of Boat Mechanics Providing{" "}
          {titleize(service)} Services
        </title>
        <meta
          name="description"
          content={`Find reliable boat mechanics who offer repair services for various boat types. Browse the list of qualified professionals and choose the right mechanic for your boat's ${titleize(
            service
          )} needs.`}
        />
        <meta
          name="og:description"
          content={`Find reliable boat mechanics who offer repair services for various boat types. Browse the list of qualified professionals and choose the right mechanic for your boat's ${titleize(
            service
          )} needs.`}
        />
        <meta
          property="og:title"
          content={`Find My Boat Mechanic | List of Boat Mechanics Providing ${titleize(
            service
          )} Services`}
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div id="boatServiceMechanicList">
        <section className="flex flex-col w-full mt-4 max-w-5xl m-auto p-6">
          <p className="mb-6 text-xl font-medium">
            {pagy.pages === 1 || pagy.pages === pagy.page
              ? mechanicAccounts.length
              : pagy.pages! * pagy.items!}{" "}
            {titleize(service)} services{" "}
            {pagy.pages! * pagy.items! >= 1920 ? "" : "near you"}
          </p>
          <motion.ul
            className="container grid gap-2 grid-cols-1 sm:grid-cols-2 md:gap-8"
            variants={listMotionContainer}
            initial="hidden"
            animate="visible"
          >
            {mechanicAccounts.map((mechanicAccount: any) => {
              if (!mechanicAccount.addresses.length) return null;
              return (
                <motion.li variants={listMotionItem} key={mechanicAccount.id}>
                  <Link
                    to={`${Path.MECHANICS}/${kebabCase(
                      mechanicAccount.addresses[0].country
                    )}/${kebabCase(
                      mechanicAccount.addresses[0].region
                    )}/${kebabCase(mechanicAccount.name)}/${
                      mechanicAccount.id
                    }`}
                    className="flex items-center p-4 bg-white border border-gray-200 rounded shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
                  >
                    <div className="flex mr-4 items-start space-x-4">
                      <img
                        className="w-8 h-8 rounded-full"
                        src={mechanicAccount.logo || MechanicPlaceholder}
                        alt={`${mechanicAccount.name} logo`}
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        {startCase(mechanicAccount.name)}
                      </p>
                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                        {compact([
                          startCase(mechanicAccount.addresses[0].addressLine1),
                          startCase(mechanicAccount.addresses[0].addressLine2),
                          capitalize(mechanicAccount.addresses[0].city),
                          capitalize(mechanicAccount.addresses[0].state),
                        ]).join(", ")}
                      </p>
                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                        {startCase(
                          mechanicAccount.addresses[0]?.address_line_1
                        )}
                      </p>
                      <p className="text-sm text-gray-900 truncate dark:text-white">
                        {mechanicAccount.providedServices.length ? (
                          mechanicAccount.providedServices
                            .map((service: any) => titleize(service.name))
                            .join(", ")
                        ) : (
                          <div className="invisible">placeholder</div>
                        )}
                      </p>
                    </div>
                  </Link>
                </motion.li>
              );
            })}
          </motion.ul>
          {pagy.page && pagy.pages && pagy.pages > 1 && (
            <div className="py-6 border-none flex items-center m-auto justify-center w-full sm:pb-4">
              <Pagination
                currentPage={pagy.page}
                onPageChange={(page) => {
                  onPagechange(page);
                }}
                nextLabel=""
                previousLabel=""
                showIcons
                totalPages={pagy.pages}
              />
            </div>
          )}
        </section>
        <Footer />
      </div>
    </>
  );
};
