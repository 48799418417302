import * as React from "react";
import { useMechanicMap } from "./useMechanicMap";
import { Map, Marker } from "react-map-gl";
import { Spinner } from "flowbite-react";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapPinIcon } from "@heroicons/react/24/solid";
import BannerLogoPlaceholder from "app/assets/images/blogBanner.png";
import classNames from "classnames";
import { find, kebabCase, startCase } from "lodash";
import { FavoriteHeart } from "app/components/favoriteHeart/favoriteHeart";
import { Link } from "react-router-dom";
import { Path } from "app/Path";

interface MechanicsMapProps {
  lat: number;
  lng: number;
  zoom: number;
  width: string | number;
  height: string | number;
  handleFavoriteClick?: any;
  hoveredMarkerId?: number;
}

export const MechanicsMap: React.FC<MechanicsMapProps> = ({
  lat,
  lng,
  zoom,
  width,
  height,
  handleFavoriteClick,
  hoveredMarkerId,
}) => {
  const {
    mapProps,
    loading,
    markers,
    selectedMarkerId,
    setSelectedMarkerId,
    favorites,
    MapChangeListener,
    onDragEnd,
  } = useMechanicMap({ lat, lng, zoom, hoveredMarkerId });

  if (loading || !mapProps) {
    return <div className="absolute top-1/2 left-1/2" />;
  }

  return (
    <Map
      initialViewState={{
        longitude: mapProps.initialViewState.longitude,
        latitude: mapProps.initialViewState.latitude,
        zoom: mapProps.initialViewState.zoom,
      }}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!}
      style={{ width: "100%", height: "100%" }}
      onDragEnd={(event: any) => {
        onDragEnd(event);
      }}
      mapStyle="mapbox://styles/mapbox/streets-v12"
    >
      {markers.map((marker: any, index: number) => (
        <div>
          <Marker
            longitude={marker.mechanicAccount.addresses[0].longitude}
            latitude={marker.mechanicAccount.addresses[0].latitude}
            style={{
              zIndex:
                !!selectedMarkerId &&
                selectedMarkerId !== marker.mechanicAccount.id
                  ? 10
                  : 20,
            }}
            key={index}
          >
            <MapPinIcon
              className={classNames(
                "w-8 h-8 hover:cursor-pointer border-none fill-blue-400 stroke-blue-800 text-white duration-200 hover:scale-125 hover:fill-blue-700 hover:stroke-blue-700",
                {
                  "fill-blue-700 stroke-blue-700 scale-125":
                    selectedMarkerId == marker.mechanicAccount.id ||
                    hoveredMarkerId == marker.mechanicAccount.id,
                }
              )}
              onClick={() => setSelectedMarkerId(marker.mechanicAccount.id)}
            />
          </Marker>
          <Link
            to={`${Path.MECHANICS}/${kebabCase(
              marker.mechanicAccount.addresses[0].country
            )}/${kebabCase(
              marker.mechanicAccount.addresses[0].region
            )}/${kebabCase(marker.mechanicAccount.name)}/${
              marker.mechanicAccount.id
            }`}
          >
            <div
              className={classNames(
                "bottom-[1rem] flex z-30 absolute p-2 left-1/2 -translate-x-1/2 transform duration-300 items-center w-full max-w-[320px] md:max-w-[480px] bg-white border cursor-pointer border-gray-200 rounded shadow md:bottom-[2rem] dark:border-gray-700 dark:bg-gray-800",
                {
                  "translate-y-0 opacity-100":
                    selectedMarkerId == marker.mechanicAccount.id,
                  "translate-y-[250px] opacity-0":
                    selectedMarkerId != marker.mechanicAccount.id,
                }
              )}
            >
              <img
                className="object-cover w-8 h-8 rounded-full"
                src={
                  marker.mechanicAccount.contact?.logo || BannerLogoPlaceholder
                }
                alt={`logo of ${marker.name}`}
              />
              <div className="flex flex-col w-full text-base p-4 leading-normal">
                <p className="text-gray-900">{marker.mechanicAccount.name}</p>
                <p className="text-gray-500">
                  {startCase(marker.mechanicAccount.addresses[0].addressLine1)}{" "}
                  {marker.mechanicAccount.addresses[0].addressLine2
                    ? `, ${startCase(
                        marker.mechanicAccount.addresses[0].addressLine2
                      )}`
                    : ""}
                </p>
              </div>
              <div className="absolute top-[0.5rem] right-[0.5rem]">
                <FavoriteHeart
                  isFavorited={
                    !!find(
                      favorites,
                      (favorite: any) =>
                        favorite.favoritable.id === marker.mechanicAccount.id
                    )
                  }
                  onFavoriteClick={(event: any) =>
                    handleFavoriteClick(event, marker.mechanicAccount)
                  }
                  onUnfavoriteClick={(event: any) =>
                    handleFavoriteClick(event, marker.mechanicAccount)
                  }
                />
              </div>
            </div>
          </Link>
        </div>
      ))}
      {markers.length ? <MapChangeListener /> : <div />}
    </Map>
  );
};
