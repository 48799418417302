import * as React from "react";
import { find, kebabCase, startCase } from "lodash";
import { useMechanicList } from "./useMechanicList";
import { Link } from "react-router-dom";
import { Path } from "app/Path";
import { MechanicAccountsProps } from "app/mechanic/mechanicProps";
import { FavoriteHeart } from "app/components/favoriteHeart/favoriteHeart";
import { titleize } from "app/utils/titleize";
import { SearchMechanics } from "app/mechanic/searchMechanics/searchMechanics";
import { FavoritesModal } from "app/components/favoritesModal/favoritesModal";
import classNames from "classnames";
import { motion } from "framer-motion";
import { listMotionContainer, listMotionItem } from "app/motion/listMotion";
import { Spinner } from "flowbite-react";
import { MechanicsMap } from "app/components/mechanicsMap/mechanicsMap";
import { isMobileOnly } from "react-device-detect";
import MechanicPlaceholder from "app/assets/images/mechanicPlaceholder.png";
import { Pagination } from "flowbite-react";
import { Footer } from "app/components/footer/footer";
import { ChevronUpIcon, MinusIcon } from "@heroicons/react/24/outline";
import { GhoastList } from "./components/ghoastList";

export interface MechanicListProps {}

export const MechanicList: React.FC = () => {
  const {
    pagy,
    distanceFromSearched,
    mechanicAccounts,
    searchedCoordinates,
    favorites,
    isFavoriteModalOpen,
    setIsFavoriteModalOpen,
    handleFavoriteClick,
    handleFavoriteListClick,
    onFavoriteWithNewList,
    loading,
    mostlikelyARegion,
    onPagechange,
    listLoading,
    hoveredMarkerId,
    setHoveredMarkerId,
    swipeHandlers,
    sliderDelta,
    addressParam,
  } = useMechanicList();

  return (
    <>
      <FavoritesModal
        isOpen={isFavoriteModalOpen}
        toggleOpen={setIsFavoriteModalOpen}
        handleFavoriteListClick={handleFavoriteListClick}
        onFavoriteWithNewList={onFavoriteWithNewList}
      />
      <div
        className={classNames(
          "relative flex flex-col md:flex-row overflow-y-hidden",
          {
            "h-[calc(100svh-70px)]": isMobileOnly,
          }
        )}
      >
        <div
          className={classNames("max-w-lg h-full w-full", {
            "h-[calc(100svh-70px)]": isMobileOnly,
          })}
        >
          <div className={classNames("p-4 w-full m-auto")}>
            <SearchMechanics searchedMechanics={mechanicAccounts} />
          </div>
          {isMobileOnly && (
            <div
              className={classNames("w-full relative h-[calc(100svh-258px)]")}
            >
              <MechanicsMap
                lat={Number(searchedCoordinates.lat)}
                lng={Number(searchedCoordinates.lng)}
                zoom={mostlikelyARegion ? 6 : 9}
                width={600}
                height={"100%"}
                hoveredMarkerId={hoveredMarkerId}
                handleFavoriteClick={handleFavoriteClick}
              />
            </div>
          )}
          <div
            className={classNames("z-30 duration-300", {
              "w-full absolute": isMobileOnly,
            })}
            style={{
              transform:
                isMobileOnly && sliderDelta !== 0
                  ? `translateY(calc(-100svh + ${sliderDelta}px))`
                  : "none",
            }}
          >
            <div
              className={classNames(
                "py-8 px-4 bg-white border-b-2 border-gray-100 text-center font-medium rounded",
                {
                  "pt-0": isMobileOnly,
                }
              )}
              {...swipeHandlers}
            >
              {isMobileOnly &&
                (sliderDelta === 0 ? (
                  <ChevronUpIcon className="w-5 h-5 mb-3 m-auto" />
                ) : (
                  <MinusIcon className="w-5 h-5 mb-3 m-auto" />
                ))}
              <p>
                {mechanicAccounts.length
                  ? `${
                      pagy.pages! === 1
                        ? mechanicAccounts.length
                        : pagy.pages! * pagy.items!
                    } mechanics found ${
                      addressParam
                        ? `${mostlikelyARegion ? "in" : "near"} ${addressParam}`
                        : "in Map area"
                    }`
                  : loading || listLoading
                  ? "Looking for mechanics..."
                  : `Sorry! We couldn’t find any mechanics ${
                      mostlikelyARegion ? "in" : "near"
                    } ${addressParam}. We will work on this.`}
              </p>
            </div>
            <motion.ul
              id="mechanicList"
              className={classNames(
                "w-full duration-300 z-30 rounded-b-lg divide-y h-[calc(100svh-272px)] bg-white overflow-y-scroll md:z-0 md:bottom-0 md:relative md:flex md:flex-col divide-gray-200 dark:divide-gray-700",
                {
                  absolute: isMobileOnly,
                }
              )}
              variants={listMotionContainer}
              initial={loading || listLoading ? "visible" : "hidden"}
              animate="visible"
            >
              {loading ? (
                <GhoastList />
              ) : (
                mechanicAccounts.map(
                  (mechanicAccount: MechanicAccountsProps, index: number) => (
                    <Link
                      to={`${Path.MECHANICS}/${kebabCase(
                        mechanicAccount.addresses[0].country
                      )}/${kebabCase(
                        mechanicAccount.addresses[0].region
                      )}/${kebabCase(mechanicAccount.name)}/${
                        mechanicAccount.id
                      }`}
                      onMouseOver={() => setHoveredMarkerId(mechanicAccount.id)}
                      onMouseOut={() => setHoveredMarkerId(null)}
                      key={index}
                    >
                      <motion.li
                        key={mechanicAccount.id}
                        className={classNames(
                          "py-6 px-4 border-b-1 border-gray-100",
                          {
                            "border-b-2": isMobileOnly,
                          }
                        )}
                        variants={listMotionItem}
                      >
                        <div className="flex items-start space-x-4">
                          <div className="flex-shrink-0">
                            <img
                              className="w-8 h-8 rounded-full"
                              src={mechanicAccount.logo || MechanicPlaceholder}
                              alt={
                                mechanicAccount.logo
                                  ? `${mechanicAccount.name} logo`
                                  : `placeholder logo for ${mechanicAccount.name}`
                              }
                            />
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                              {startCase(mechanicAccount.name)}
                            </p>
                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                              {mechanicAccount.mobileService?.addresses ? (
                                <>
                                  Services areas of{" "}
                                  {mechanicAccount?.mobileService?.addresses.map(
                                    (
                                      address: any,
                                      index: number,
                                      self: any
                                    ) => (
                                      <span key={address.id}>
                                        {titleize(address.city)}
                                        {index !== self.length - 1
                                          ? ","
                                          : ""}{" "}
                                      </span>
                                    )
                                  )}
                                </>
                              ) : (
                                startCase(
                                  mechanicAccount.addresses[0]?.address_line_1
                                )
                              )}
                            </p>
                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                              {!mostlikelyARegion
                                ? `${distanceFromSearched(
                                    {
                                      lat: searchedCoordinates.lat,
                                      lng: searchedCoordinates.lng,
                                    },
                                    {
                                      lat: mechanicAccount.addresses[0]
                                        .latitude,
                                      lng: mechanicAccount.addresses[0]
                                        .longitude,
                                    }
                                  )} from ${addressParam || "Map area"}`
                                : addressParam}
                            </p>
                            <p className="text-sm text-gray-900 truncate dark:text-white">
                              {mechanicAccount.providedServices
                                .map((service) => titleize(service.name))
                                .join(", ")}
                            </p>
                          </div>
                          <FavoriteHeart
                            isFavorited={
                              !!find(
                                favorites,
                                (favorite: any) =>
                                  favorite.favoritable.id === mechanicAccount.id
                              )
                            }
                            onFavoriteClick={(event) =>
                              handleFavoriteClick(event, mechanicAccount)
                            }
                            onUnfavoriteClick={(event) =>
                              handleFavoriteClick(event, mechanicAccount)
                            }
                          />
                        </div>
                      </motion.li>
                    </Link>
                  )
                )
              )}
              {pagy.page && pagy.pages && pagy.pages > 1 && (
                <motion.li
                  className="py-6 border-none flex items-center justify-center w-full sm:pb-4"
                  variants={listMotionItem}
                >
                  <Pagination
                    currentPage={pagy.page}
                    onPageChange={(page) => {
                      onPagechange(page);
                    }}
                    nextLabel=""
                    previousLabel=""
                    showIcons
                    totalPages={pagy.pages}
                  />
                </motion.li>
              )}
              {!isMobileOnly && <Footer />}
            </motion.ul>
          </div>
        </div>
        {!isMobileOnly && (
          <div className={classNames("w-full")}>
            <MechanicsMap
              lat={Number(searchedCoordinates.lat)}
              lng={Number(searchedCoordinates.lng)}
              zoom={mostlikelyARegion ? 6 : 9}
              width={600}
              height={"100%"}
              hoveredMarkerId={hoveredMarkerId}
              handleFavoriteClick={handleFavoriteClick}
            />
          </div>
        )}
      </div>
    </>
  );
};
