import Geocode from "react-geocode";
import mapboxgl from "mapbox-gl";
import http from "app/requests/axiosInstance";

const Geocoder = Geocode;
Geocoder.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API!);
Geocoder.setLanguage("en");

export const geoCoderFromAddress: any = async (value: string) => {
  const { results } = await Geocoder.fromAddress(value);
  let city, state, country, postalCode;
  for (let i = 0; i < results[0].address_components.length; i++) {
    for (let j = 0; j < results[0].address_components[i].types.length; j++) {
      switch (results[0].address_components[i].types[j]) {
        case "locality":
          city = results[0].address_components[i].long_name;
          break;
        case "administrative_area_level_1":
          state = results[0].address_components[i].long_name;
          break;
        case "country":
          country = results[0].address_components[i].long_name;
          break;
        case "postal_code":
          postalCode = results[0].address_components[i].long_name;
          break;
      }
    }
  }

  return {
    city,
    state,
    country,
    postalCode,
    results,
  };
};

export const getReverseGeocode = async (longitude: any, latitude: any) => {
  try {
    const response = await http.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process
        .env.REACT_APP_MAPBOX_ACCESS_TOKEN!}`
    );

    return response.data;
  } catch (error) {
    console.error("Error occurred during reverse geocoding:", error);
  }
};
