import { useState, useEffect } from "react";
import { useQuery } from "app/utils/useQuery";
import { useNavigate } from "react-router-dom";
import { getFormValues } from "app/utils/getFormValues";
import { googleOauth, userLogin, userSignup } from "app/redux/usersSlice";
import { useDispatch } from "react-redux";
import { toast, ToastType } from "app/utils/toast";
import { useGoogleLogin } from "@react-oauth/google";
// import http from "app/requests/axiosInstance";
// import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import axios from "axios";
import { loginTrack, newSignupTrack } from "app/utils/track/track";

export const useAuthModal = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authParam = query.get("auth") || "";

  const [isOpened, setIsOpened] = useState(false);
  const [authPage, setAuthPage] = useState<string>(authParam || "");

  const handleLoginSubmit = async (event: any) => {
    event.preventDefault();
    const { email, password } = getFormValues(event.target);
    try {
      const user = await dispatch(
        userLogin({ email, password }) as any
      ).unwrap();
      toast(ToastType.SUCCESS, "You have successfully logged in.");
      closeModal();
      loginTrack({
        id: user.id,
        email: user.email,
      });
    } catch (error: any) {
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong with your login."
      );
    }
  };

  const handleGoogleOauth = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const { data } = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );
      const user = await dispatch(
        googleOauth({ ...data, role: "customer" }) as any
      ).unwrap();
      toast(ToastType.SUCCESS, "You have successfully logged in with Google.");
      closeModal();

      newSignupTrack({
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.roles[0].name,
      });
    },
    onError: (errorResponse) => {
      toast(
        ToastType.ERROR,
        errorResponse.error_description ||
          "Something went wrong with your Google login."
      );
    },
  });

  // const handleFacebookOauth = async () => {
  //   try {
  //     await FacebookLoginClient.init({
  //       appId: process.env.REACT_APP_FACEBOOK_OAUTH_CLIENT_ID!,
  //     });
  //     await FacebookLoginClient.login(
  //       (response: any) => {
  //         response);
  //       },
  //       {
  //         scope: "email, public_profile",
  //       }
  //     );
  //   } catch (error: any) {
  //     error);
  //     toast(ToastType.ERROR, "Something went wrong with your Facebook login.");
  //   }
  // };

  const handleSignupSubmit = async (event: any) => {
    event.preventDefault();
    const { email, password } = getFormValues(event.target);
    try {
      const user = await dispatch(
        userSignup({
          email,
          password,
          accountType: "CustomerAccount",
          role: "customer",
          firstName: email,
        }) as any
      ).unwrap();
      closeModal();
      toast(ToastType.SUCCESS, "You have successfully created an account!");
      newSignupTrack({ id: user.id, email: user.email });
    } catch (error: any) {
      toast(
        ToastType.ERROR,
        error?.data?.status.message || "Something went wrong with your sign up."
      );
    }
  };

  const closeModal = () => {
    query.delete("auth");
    navigate({
      pathname: window.location.pathname,
      search: query.toString(),
    });
    setIsOpened(false);
  };

  useEffect(() => {
    if (authParam) {
      setAuthPage(authParam);
      setIsOpened(true);
    }
  }, [authParam]);

  return {
    isOpened,
    setIsOpened,
    closeModal,
    authPage,
    setAuthPage,
    handleLoginSubmit,
    handleSignupSubmit,
    handleGoogleOauth,
  };
};
