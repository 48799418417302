import * as React from "react";
import { SearchMechanics } from "app/mechanic/searchMechanics/searchMechanics";
// import GridBG from "app/assets/images/gridBg.png";
import YoungMech from "app/assets/images/youngMech.png";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { useHome } from "./useHome";
import classNames from "classnames";
import { BlogList } from "app/blog/components/blogList/blogList";
import { Path } from "app/Path";
import { Link } from "react-router-dom";
import { MechanicAreas } from "app/mechanicAreas/mechanicAreas";
import { MechanicAreasCategory } from "app/mechanicAreas/useMechanicAreas";
import { Footer } from "app/components/footer/footer";
import { isMobileOnly } from "react-device-detect";
import { Services as BoatServices } from "app/boatServices/services/services";
import MobileHeaderGradient from "app/assets/images/home/header/mobile/gradient.png";
import MobileHeaderWave from "app/assets/images/home/header/mobile/headerWave.png";
import DesktopHeaderWave from "app/assets/images/home/header/desktop/headerWave.png";
import DesktopHeaderGradient from "app/assets/images/home/header/desktop/gradient.png";
import "./home.css";

export interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  const { ipData, searchedMechanics, onExploreMechanicsClick } = useHome();

  return (
    <>
      <div className="w-full">
        {isMobileOnly ? (
          <div className="h-[176px] top-0 left-0 w-full">
            <img
              src={MobileHeaderWave}
              alt="Header Wave"
              className="absolute top-0 left-0 w-full"
            />
            <img
              src={MobileHeaderGradient}
              alt="Header Gradient"
              className="absolute -z-10 top-[20px] left-0 w-full waveBlurDiv"
            />
          </div>
        ) : (
          <div className="h-[176px] top-0 left-0 w-full">
            <img
              src={DesktopHeaderWave}
              alt="Header Wave"
              className="absolute top-0 left-0 w-full"
            />
            <img
              src={DesktopHeaderGradient}
              alt="Header Gradient"
              className="absolute -z-10 top-[20px] left-0 w-full waveBlurDiv"
            />
          </div>
        )}
      </div>
      <div
        className={`flex flex-col gap-28 items-center relative w-full bg-no-repeat overflow-hidden px-1 md:px-4`}
      >
        <section className="flex gap-7 w-full max-w-5xl md:justify-center flex-col items-center">
          <h1
            className={classNames(
              "leading-tight z-10 text-4xl font-bold md:font-extrabold mt-10 md:text-5xl md:mt-0",
              {
                "self-start": isMobileOnly,
              }
            )}
          >
            Find boat mechanics <br className="md:hidden" /> near{" "}
            <span className="text-blue-600">you.</span>
          </h1>
          {ipData && (
            <div
              className={classNames(
                "flex items-center py-1 px-2 rounded border z-10 border-blue-600 bg-white",
                {
                  "text-sm": isMobileOnly,
                }
              )}
            >
              <span className="bg-blue-200 rounded text-blue-700 px-2 py-1 mr-4">
                Smart find
              </span>
              <>
                <span className="mr-4 md:mr-8">
                  There are {searchedMechanics.length} mechanics near{" "}
                  <span className="font-medium">
                    {ipData.city}, {ipData.region}.
                  </span>
                </span>
                <button
                  onClick={onExploreMechanicsClick}
                  className="flex gap-1 cursor-pointer items-center text-sm text-blue-600"
                >
                  Explore <ArrowLongRightIcon className="w-5 pt-[2px]" />
                </button>
              </>
            </div>
          )}
          <div className="w-full relative flex">
            <div className="relative w-full backdrop-blur-sm z-10 md:max-w-5xl md:p-2">
              <SearchMechanics />
            </div>
          </div>
        </section>
        <section className="flex m-auto w-full max-w-[1400px] md:justify-center flex-col items-center">
          <h2 className="font-semibold text-center md:text-2xl">
            Boat mechanics near these major areas
          </h2>
          <h3 className="mb-6 text-center">
            Start exploring all the mechanics & their services.
          </h3>
          <div className={classNames("w-full rounded md:bg-white md:p-6", {})}>
            <MechanicAreas category={MechanicAreasCategory.TOP} />
          </div>
        </section>
        <section className="flex m-auto max-w-[848px] md:justify-center flex-col items-center">
          <h2 className="font-semibold md:text-2xl">Boat Services</h2>
          <h3 className="mb-6 text-center">
            Search for a mechanic that provides that services you need.
          </h3>
          <BoatServices />
        </section>
        <section className="flex m-auto max-w-[928px] md:justify-center flex-col items-center">
          <Link to={Path.BLOG}>
            <h2 className="mb-6 font-semibold transition duration-150 border-b-2 border-transparent hover:border-blue-500 md:text-2xl">
              Resources
            </h2>
          </Link>
          <BlogList />
          <Link to={Path.BLOG} className="mt-6 underline text-blue-600">
            See more
          </Link>
        </section>
      </div>
      <Footer />
    </>
  );
};
