import { MechanicAccountsProps } from "app/mechanic/mechanicProps";
import { titleize } from "app/utils/titleize";
import * as React from "react";
import { ListItem } from "../listItem";
import { getBoatServices } from "app/dictionaries/boatServices";
import { camelCase } from "lodash";

export interface ServicesProps {
  mechanicAccount: MechanicAccountsProps;
}

export const Services: React.FC<ServicesProps> = (props) => {
  const {
    mechanicAccount: {
      providedServices,
      serviceableBrands,
      serviceableModels,
      contact,
    },
  } = props;
  return (
    <ul className="flex flex-col w-full rounded-b-lg bg-white border p-4 divide-y divide-gray-200 dark:divide-gray-700 sm:rounded">
      {providedServices.length > 0 && (
        <li className="pb-2.5 sm:pb-4">
          <div className="flex items-start space-x-4">
            <p className="leading-tight/text-xl/font-medium mb-7 text-gray-900 truncate dark:text-white">
              Services provided
            </p>
          </div>
          {providedServices.map((service) => {
            const { name, black32Icon, iconAlt } = getBoatServices(
              camelCase(service.name)
            );
            return (
              <a
                href={
                  contact.email
                    ? `mailto:${contact.email}?subject=Inquiry about ${titleize(
                        service.name
                      )} ${
                        service.name.includes("boat_services") ? "" : "services"
                      }`
                    : `tel:${contact.phone}`
                }
                className="cursor-pointer underline"
              >
                <ListItem
                  center
                  icon={<img src={black32Icon} className="w-8" alt={iconAlt} />}
                  content={name}
                  iconContentGap="pr-2"
                />
              </a>
            );
          })}
        </li>
      )}
      {serviceableBrands.length > 0 && (
        <li className="pt-8	pb-2.5 sm:pb-4">
          <div className="flex items-start space-x-4">
            <p className="leading-tight/text-xl/font-medium mb-7 text-gray-900 truncate dark:text-white">
              Brands serviced
            </p>
          </div>
          {serviceableBrands.map((service) => (
            <ListItem center content={titleize(service.name)} />
          ))}
        </li>
      )}
      {serviceableModels.length > 0 && (
        <li className="pt-8 pb-2.5 sm:pb-4">
          <div className="flex items-start space-x-4">
            <p className="leading-tight/text-xl/font-medium mb-7 text-gray-900 truncate dark:text-white">
              Serviced models
            </p>
          </div>
          {serviceableModels.map((service) => (
            <ListItem center content={titleize(service.name)} />
          ))}
        </li>
      )}
    </ul>
  );
};
