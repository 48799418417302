export interface FormValues {
  [fieldName: string]: string;
}

enum CheckBoxStates {
  CHECKED = 'checked',
  UNCHECKED = 'unchecked',
}

const configureFormValues = (element: HTMLInputElement) => {
  const { name, type } = element;
  if (name === 'email') {
    return element.value.toLowerCase();
  } else if (type === 'checkbox') {
    return element.checked ? CheckBoxStates.CHECKED : CheckBoxStates.UNCHECKED;
  } else {
    return element.value;
  }
};
// Gets the values for each input field in a form
export function getFormValues(
  form: EventTarget & Element,
  includeHidden: boolean = true
): FormValues {
  return Array.from(form.querySelectorAll('input, select, textarea'))
    .filter((element: any) => includeHidden || !element.hidden)
    .reduce(
      (formValues: FormValues, element: any) => ({
        ...formValues,
        [element.name]: configureFormValues(element),
      }),
      {}
    );
}
