import * as React from "react";

export interface PrivacyPolicyProps {}

export const PrivacyPolicy: React.FC<PrivacyPolicyProps> = (props) => {
  const {} = props;
  return (
    <div className="mt-8 px-4 max-w-4xl m-auto">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        Welcome to FindMyBoatMechanic.com. We respect your privacy and are
        committed to protecting it through our compliance with this policy.
      </p>
      <ol className="flex flex-col gap-4 mb-4">
        <li>
          <span className="font-bold">Information We Collect: </span>
          We may collect and use personal information that you voluntarily
          provide to us, such as when you claim your business or contact us with
          inquiries. This information may include your name, email address,
          phone number, and business details.
        </li>
        <li>
          <span className="font-bold">Use of Your Information: </span>
          We use the information we collect from you to manage our website,
          provide our services, and respond to your inquiries. We do not sell,
          rent, or lease your personal information to third parties.
        </li>
        <li>
          <span className="font-bold">Cookies: </span>
          We may use cookies and similar tracking technologies to track the
          activity on our website and hold certain information. Cookies are
          files with a small amount of data which may include an anonymous
          unique identifier. You can instruct your browser to refuse all cookies
          or to indicate when a cookie is being sent.
        </li>
        <li>
          <span className="font-bold">Security: </span>
          We strive to maintain the safety of your personal information.
          However, transmission of information via the internet is not
          completely secure and we cannot guarantee the security of your
          information transmitted to our website.
        </li>
        <li>
          <span className="font-bold">Third-Party Links: </span>
          Our website may contain links to other websites that are not operated
          by us. If you click on a third-party link, you will be directed to
          that third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </li>
        <li>
          <span className="font-bold">Changes to This Privacy Policy: </span>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </li>
        <li>
          <span className="font-bold">Contact: </span>
          If you have any questions about this Privacy Policy, please contact us
          at{" "}
          <a
            className=" text-blue-500 underline hover:text-blue-700"
            href="mailto:al@tacit.ventures?subject=Inquiry about Find my boat mechanics Privacy Policy"
          >
            cody@tacit.ventures
          </a>
          .
        </li>
      </ol>
    </div>
  );
};
