import alabama from "app/assets/images/regions/alabama.jpg";
import alaska from "app/assets/images/regions/alaska.jpg";
import arizona from "app/assets/images/regions/arizona.jpg";
import arkansas from "app/assets/images/regions/arkansas.jpg";
import americanSamoa from "app/assets/images/regions/americanSamoa.jpg";
import california from "app/assets/images/regions/california.jpg";
import colorado from "app/assets/images/regions/colorado.jpg";
import connecticut from "app/assets/images/regions/connecticut.jpg";
import delaware from "app/assets/images/regions/delaware.jpg";
import florida from "app/assets/images/regions/florida.jpg";
import georgia from "app/assets/images/regions/georgia.jpg";
import guam from "app/assets/images/regions/guam.jpg";
import hawaii from "app/assets/images/regions/hawaii.jpg";
import idaho from "app/assets/images/regions/idaho.jpg";
import illinois from "app/assets/images/regions/illinois.jpg";
import indiana from "app/assets/images/regions/indiana.jpg";
import iowa from "app/assets/images/regions/iowa.jpg";
import kansas from "app/assets/images/regions/kansas.jpg";
import kentucky from "app/assets/images/regions/kentucky.jpg";
import louisiana from "app/assets/images/regions/louisiana.jpg";
import maine from "app/assets/images/regions/maine.jpg";
import maryland from "app/assets/images/regions/maryland.jpg";
import massachusetts from "app/assets/images/regions/massachusetts.jpg";
import michigan from "app/assets/images/regions/michigan.jpg";
import minnesota from "app/assets/images/regions/minnesota.jpg";
import mississippi from "app/assets/images/regions/mississippi.jpg";
import missouri from "app/assets/images/regions/missouri.jpg";
import montana from "app/assets/images/regions/montana.jpg";
import nebraska from "app/assets/images/regions/nebraska.jpg";
import nevada from "app/assets/images/regions/nevada.jpg";
import newHampshire from "app/assets/images/regions/newHampshire.jpg";
import newJersey from "app/assets/images/regions/newJersey.jpg";
import newMexico from "app/assets/images/regions/newMexico.jpg";
import newYork from "app/assets/images/regions/newYork.jpg";
import northCarolina from "app/assets/images/regions/northCarolina.jpg";
import northDakota from "app/assets/images/regions/northDakota.jpg";
import northernMarianaIslands from "app/assets/images/regions/northernMarianaIslands.jpg";
import ohio from "app/assets/images/regions/ohio.jpg";
import oklahoma from "app/assets/images/regions/oklahoma.jpg";
import oregon from "app/assets/images/regions/oregon.jpg";
import pennsylvania from "app/assets/images/regions/pennsylvania.jpg";
import puertoRico from "app/assets/images/regions/puertoRico.jpg";
import rhodeIsland from "app/assets/images/regions/rhodeIsland.jpg";
import southCarolina from "app/assets/images/regions/southCarolina.jpg";
import southDakota from "app/assets/images/regions/southDakota.jpg";
import tennessee from "app/assets/images/regions/tennessee.jpg";
import texas from "app/assets/images/regions/texas.jpg";
import utah from "app/assets/images/regions/utah.jpg";
import vermont from "app/assets/images/regions/vermont.jpg";
import virginia from "app/assets/images/regions/virginia.jpg";
import virginIslands from "app/assets/images/regions/virginIslands.jpg";
import washington from "app/assets/images/regions/washington.jpg";
import westVirginia from "app/assets/images/regions/westVirginia.jpg";
import wisconsin from "app/assets/images/regions/wisconsin.jpg";
import wyoming from "app/assets/images/regions/wyoming.jpg";
import alberta from "app/assets/images/regions/alberta.jpg";
import britishColumbia from "app/assets/images/regions/britishColumbia.jpg";
import manitoba from "app/assets/images/regions/manitoba.jpg";
import newBrunswick from "app/assets/images/regions/newBrunswick.jpg";
import newfoundlandAndLabrador from "app/assets/images/regions/newfoundlandAndLabrador.jpg";
import northwestTerritories from "app/assets/images/regions/northwestTerritories.jpg";
import novaScotia from "app/assets/images/regions/novaScotia.jpg";
import nunavut from "app/assets/images/regions/nunavut.jpg";
import ontario from "app/assets/images/regions/ontario.png";
import princeEdwardIsland from "app/assets/images/regions/princeEdwardIsland.jpg";
import quebec from "app/assets/images/regions/quebec.jpg";
import saskatchewan from "app/assets/images/regions/saskatchewan.jpg";
import yukon from "app/assets/images/regions/yukon.jpg";

export const regionImages: any = {
  alabama,
  alaska,
  arizona,
  arkansas,
  americanSamoa,
  california,
  colorado,
  connecticut,
  delaware,
  florida,
  georgia,
  guam,
  hawaii,
  idaho,
  illinois,
  indiana,
  iowa,
  kansas,
  kentucky,
  louisiana,
  maine,
  maryland,
  massachusetts,
  michigan,
  minnesota,
  mississippi,
  missouri,
  montana,
  nebraska,
  nevada,
  newHampshire,
  newJersey,
  newMexico,
  newYork,
  northCarolina,
  northDakota,
  northernMarianaIslands,
  ohio,
  oklahoma,
  oregon,
  pennsylvania,
  puertoRico,
  rhodeIsland,
  southCarolina,
  southDakota,
  tennessee,
  texas,
  utah,
  vermont,
  virginia,
  virginIslands,
  washington,
  westVirginia,
  wisconsin,
  wyoming,
  alberta,
  britishColumbia,
  manitoba,
  newBrunswick,
  newfoundlandAndLabrador,
  northwestTerritories,
  novaScotia,
  nunavut,
  ontario,
  princeEdwardIsland,
  quebec,
  saskatchewan,
  yukon,
};
