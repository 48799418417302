import * as React from "react";
import { passwordChange, selectUser, userUpdate } from "app/redux/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { getFormValues } from "app/utils/getFormValues";
import { toast, ToastType } from "app/utils/toast";
import { getBase64FromFile } from "app/utils/getBase64";

export const useUser = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const onAvatarSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const file = event.target.files[0];
      let formData = new FormData() as any;
      formData.avatar = { data: await getBase64FromFile(file) };
      await dispatch(userUpdate(formData) as any).unwrap();
      toast(ToastType.SUCCESS, "Your avatar has been updated.");
    } catch (error: any) {
      console.log(error);
      toast(
        ToastType.ERROR,
        error?.data?.error ||
          "Something went wrong during changing your avatar."
      );
    }
  };

  const onGeneralInformationSubmit = async (event: any) => {
    event.preventDefault();
    const { firstName, lastName, email } = getFormValues(event.target);
    try {
      await dispatch(
        userUpdate({
          firstName: firstName,
          lastName: lastName,
          email: email,
        }) as any
      ).unwrap();
      toast(ToastType.SUCCESS, "Your information has been updated.");
    } catch (error: any) {
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong during logging in."
      );
      console.log(error);
    }
  };

  const onPasswordChangeSubmit = async (event: any) => {
    event.preventDefault();
    const { currentPassword, newPassword, passwordConfirmation } =
      getFormValues(event.target);
    try {
      await dispatch(
        passwordChange({
          currentPassword: currentPassword,
          newPassword: newPassword,
          passwordConfirmation: passwordConfirmation,
        }) as any
      ).unwrap();
      toast(ToastType.SUCCESS, "Your password has been updated.");
    } catch (error: any) {
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong with your password change."
      );
    }
  };

  return {
    user,
    onAvatarSubmit,
    onGeneralInformationSubmit,
    onPasswordChangeSubmit,
  };
};
