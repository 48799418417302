import { Button, Modal, TextInput } from "flowbite-react";
import { capitalize } from "lodash";
import * as React from "react";
import { useAuthModal } from "./useAuthModal";
// import FacebookLogin from "@greatsumini/react-facebook-login";
// import facebookIcon from "app/assets/images/icons/facebook.svg";
import googleIcon from "app/assets/images/icons/google.svg";

export interface AuthModalProps {}

export const AuthModal: React.FC<AuthModalProps> = (props) => {
  const {
    isOpened,
    closeModal,
    authPage,
    setAuthPage,
    handleSignupSubmit,
    handleLoginSubmit,
    handleGoogleOauth,
  } = useAuthModal();

  return (
    <React.Fragment>
      <Modal
        show={isOpened}
        size="xl"
        popup={true}
        onClose={closeModal}
        dismissible={true}
        className="!h-full"
      >
        <Modal.Header />
        <Modal.Body>
          <div className="flex flex-col gap-4 pb-4">
            <p className="text-center font-medium text-gray-900 dark:text-white">
              Log in or sign up <br /> to add mechanics to your favorite.
            </p>
            <div className="w-full gap-2 flex flex-col justify-center">
              <button
                onClick={() => handleGoogleOauth()}
                className="w-full inline-flex items-center justify-center py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                <svg
                  className="w-5 h-5 mr-2"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_13183_10121)">
                    <path
                      d="M20.3081 10.2303C20.3081 9.55056 20.253 8.86711 20.1354 8.19836H10.7031V12.0492H16.1046C15.8804 13.2911 15.1602 14.3898 14.1057 15.0879V17.5866H17.3282C19.2205 15.8449 20.3081 13.2728 20.3081 10.2303Z"
                      fill="#3F83F8"
                    />
                    <path
                      d="M10.7019 20.0006C13.3989 20.0006 15.6734 19.1151 17.3306 17.5865L14.1081 15.0879C13.2115 15.6979 12.0541 16.0433 10.7056 16.0433C8.09669 16.0433 5.88468 14.2832 5.091 11.9169H1.76562V14.4927C3.46322 17.8695 6.92087 20.0006 10.7019 20.0006V20.0006Z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.08857 11.9169C4.66969 10.6749 4.66969 9.33008 5.08857 8.08811V5.51233H1.76688C0.348541 8.33798 0.348541 11.667 1.76688 14.4927L5.08857 11.9169V11.9169Z"
                      fill="#FBBC04"
                    />
                    <path
                      d="M10.7019 3.95805C12.1276 3.936 13.5055 4.47247 14.538 5.45722L17.393 2.60218C15.5852 0.904587 13.1858 -0.0287217 10.7019 0.000673888C6.92087 0.000673888 3.46322 2.13185 1.76562 5.51234L5.08732 8.08813C5.87733 5.71811 8.09302 3.95805 10.7019 3.95805V3.95805Z"
                      fill="#EA4335"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_13183_10121">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Continue with Google
              </button>
              {/* <FacebookLogin
                  appId="1088597931155576"
                  onSuccess={(response) => {
                    console.log("Login Success!", response);
                  }}
                  onFail={(error) => {
                    console.log("Login Failed!", error);
                  }}
                  onProfileSuccess={(response) => {
                    console.log("Get Profile Success!", response);
                  }}
                  render={({ onClick, logout }) => (
                    <button
                      onClick={onClick}
                      className="flex border rounded justify-center items-center py-1 px-2"
                    >
                      <img
                        src={facebookIcon}
                        className="w-6 h-6 mr-2"
                        alt="facebook image"
                      />{" "}
                      <p className="text-sm font-medium">
                        Continue with Facebook
                      </p>
                    </button>
                  )}
                /> */}
            </div>
          </div>
          <form
            onSubmit={
              authPage === "signup" ? handleSignupSubmit : handleLoginSubmit
            }
            className="space-y-6"
          >
            <div className="inline-flex items-center justify-center w-full">
              <hr className="w-full h-px my-6 bg-gray-200 border-0 dark:bg-gray-700" />
              <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">
                {" "}
                Log in with Email
              </span>
            </div>

            <div>
              <div className="mb-2 block">
                <label className="text-sm font-medium">Email</label>
              </div>
              <TextInput
                name="email"
                placeholder="name@company.com"
                required={true}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <label className="text-sm font-medium">Password</label>
              </div>
              <TextInput name="password" type="password" required={true} />
            </div>
            {/* <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <Checkbox id="remember" />
                <label htmlFor="remember" className="text-sm text-gray-500">
                  Remember me
                </label>
              </div>
              <a
                href="/modal"
                className="text-xs text-blue-600 hover:underline dark:text-blue-500"
              >
                Forgot Password?
              </a>
            </div> */}
            <Button type="submit" className="w-full">
              {authPage === "signup" ? "Sign up" : "Login"}
            </Button>
            <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
              {capitalize(
                authPage === "signup"
                  ? "Already have an account?"
                  : "Don't have an account yet?"
              )}{" "}
              <span
                onClick={() =>
                  setAuthPage(authPage === "signup" ? "login" : "signup")
                }
                className="text-blue-700 hover:underline cursor-pointer dark:text-blue-500"
              >
                {authPage === "signup" ? "Login" : "Sign up"}
              </span>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
