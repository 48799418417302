import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import http from "app/requests/axiosInstance";
import { MechanicAccountsProps } from "app/mechanic/mechanicProps";

interface SearchMechanicState {
  mechanicAccounts: MechanicAccountsProps[];
  pagy: {
    page?: number;
    pages?: number;
    items?: number;
    next?: number;
  };
}

// Define the initial state using that type
const initialState = {
  mechanicAccounts: [],
  pagy: {},
} as SearchMechanicState;

export const getSearchedMechanics = createAsyncThunk(
  "mechanics/searchMechanicStatus",
  async (
    { searchQuery, searchType, page, providedServices, snakeCase = false }: any,
    thunkAPI
  ) => {
    try {
      const { data }: any = await http.get("/v1/mechanic_accounts", {
        params: {
          [searchType]: searchQuery,
          page,
          providedServices,
          snakeCase,
        },
      });

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const searchMechanicSlice = createSlice({
  name: "searchedMechanics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getSearchedMechanics.fulfilled, (state: any, action) => {
      const newState = { ...state, ...action.payload };
      return newState;
    });
  },
});

export const selectSearchMechanics = (state: RootState) =>
  state.searchedMechanics;

export default searchMechanicSlice.reducer;
