import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import http from "app/requests/axiosInstance";
import { MechanicAccountsProps } from "app/mechanic/mechanicProps";
import { batch } from "react-redux";
import { getMechanicFavoriteLists } from "./mechanicFavoritesListSlice";

interface mechanicFavortesState {
  id: number;
  favoritable: MechanicAccountsProps;
  favoritor: {
    id: number;
  };
  pagy: any;
}

// Define the initial state using that type
const initialState = [] as mechanicFavortesState[];

export const getMechanicFavorites = createAsyncThunk(
  "mechanics/getFavoritesState",
  async ({}: any, thunkAPI) => {
    try {
      const { data }: any = await http.get("/v1/favorites");
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const addMechanicFavorite = createAsyncThunk(
  "mechanics/addMechanicFavoriteState",
  async ({ favoritorId, favoritableId }: any, thunkAPI) => {
    try {
      const { data } = await http.post(`/v1/favorites`, {
        favorite: {
          favoritorId: favoritorId,
          favoritorType: "FavoriteList",
          favoritableId: favoritableId,
          favoritableType: "MechanicAccount",
        },
      });
      batch(() => {
        thunkAPI.dispatch(getMechanicFavoriteLists({}));
        thunkAPI.dispatch(getMechanicFavorites({}));
      });
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeMechanicFavorite = createAsyncThunk(
  "mechanics/removeMechanicFavoriteState",
  async (favoriteId: number, thunkAPI) => {
    try {
      const { data } = await http.delete(`/v1/favorites/${favoriteId}`);
      batch(() => {
        thunkAPI.dispatch(getMechanicFavoriteLists({}));
        thunkAPI.dispatch(getMechanicFavorites({}));
      });
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const mechanicFavoritesSlice = createSlice({
  name: "mechanicfavorites",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getMechanicFavorites.fulfilled, (state: any, action) => {
      return action.payload;
    });
    builder.addCase(addMechanicFavorite.fulfilled, (state: any, action) => {});
    builder.addCase(
      removeMechanicFavorite.fulfilled,
      (state: any, action) => {}
    );
  },
});

export const selectMechanicFavorites = (state: RootState) =>
  state.mechanicFavorites;

export default mechanicFavoritesSlice.reducer;
