import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import http from "app/requests/axiosInstance";
import { MechanicAccountsProps } from "app/mechanic/mechanicProps";

// Define the initial state using that type
const initialState = {} as MechanicAccountsProps;

export const getSelectedMechanic = createAsyncThunk(
  "mechanics/selectedMechanicStatus",
  async (id: number, thunkAPI) => {
    try {
      const { data }: any = await http.get(`/v1/mechanic_accounts/${id}`);
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const selectedMechanicSlice = createSlice({
  name: "selectedMechanic",
  initialState,
  reducers: {
    setSelectedMechanic(state, action) {
      return Object.assign({}, state, action.payload);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getSelectedMechanic.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
  },
});

export const { setSelectedMechanic } = selectedMechanicSlice.actions;

export const selectSelectedMechanic = (state: RootState) =>
  state.selectedMechanic;

export default selectedMechanicSlice.reducer;
