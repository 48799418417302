import { configureStore } from "@reduxjs/toolkit";
import searchMechanicSlice from "app/redux/searchMechanicsSlice";
import selectedMechanicSlice from "app/redux/selectedMechanicSlice";
import mechanicFavoritesListSlice from "./redux/mechanicFavoritesListSlice";
import mechanicFavoritesSlice from "./redux/mechanicFavoritesSlice";
import usersSlice from "./redux/usersSlice";
import ipDataSlice from "./redux/ipDataSlice";
import mechanicAreasSlice from "./redux/mechanicAreasSlice";

export const store = configureStore({
  reducer: {
    searchedMechanics: searchMechanicSlice,
    selectedMechanic: selectedMechanicSlice,
    mechanicFavoritesList: mechanicFavoritesListSlice,
    mechanicFavorites: mechanicFavoritesSlice,
    mechanicAreas: mechanicAreasSlice,
    ipData: ipDataSlice,
    user: usersSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
