import * as React from "react";
import { useBlog } from "./useBlogShow";
import parse from "html-react-parser";
import "./blogShow.css";
import { Spinner } from "flowbite-react";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import ProgressBar from "react-scroll-progress-bar";
import { Footer } from "app/components/footer/footer";

export const BlogShow: React.FC = () => {
  const { blogData, loading }: any = useBlog();

  if (loading || !blogData) {
    return <Spinner size={"lg"} className="absolute top-1/2 left-1/2" />;
  }

  const parsedHTML = parse(blogData.content);

  return (
    <>
      <ProgressBar bgcolor="#1c64f2" />
      <Helmet>
        <title>{blogData.metaTitle}</title>
        <meta name="description" content={blogData.metaDescription} />
        <meta property="og:title" content={blogData.metaTitle} />
        <meta property="og:description" content={blogData.metaDescription} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="flex flex-col m-auto py-20 px-4 max-w-3xl blog">
        <h1 className="text-3xl mb-2 font-bold">{blogData.title}</h1>
        <h2 className="text-xl font-normal">{blogData.subtitle}</h2>
        <div className="flex flex-col my-4">
          <p>
            {blogData.publisher.firstName} {blogData.publisher.lastName}
          </p>
          <p className="text-gray-500 text-sm">
            {format(new Date(blogData.updatedAt), "PPP")}
          </p>
        </div>
        {parsedHTML}

        <a
          href="mailto:al@tacit.ventures?subject=Hi I would like to see more addition content on Find my boat mechanic"
          className="btn-blue m-auto mt-8"
        >
          Tell us what other content you’d like to see
        </a>
      </div>
      <Footer />
    </>
  );
};
