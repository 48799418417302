import { titleize } from "app/utils/titleize";

// Blue icons 64
import GeneralMaintenanceBlue64Icon from "app/assets/images/icons/boatServices/blue64/generalMaintenance.svg";
import MotorServicesBlue64Icon from "app/assets/images/icons/boatServices/blue64/motorServices.svg";
import ElectronicRepairBlue64Icon from "app/assets/images/icons/boatServices/blue64/electronicRepair.svg";
import ControlSystemsBlue64Icon from "app/assets/images/icons/boatServices/blue64/controlSystems.svg";
import SurveyingBlue64Icon from "app/assets/images/icons/boatServices/blue64/surveying.svg";
import HullFiberglassBlue64Icon from "app/assets/images/icons/boatServices/blue64/hullFiberglass.svg";
import BodyWorkBlue64Icon from "app/assets/images/icons/boatServices/blue64/bodyWork.svg";
import UpholsteryCanvasBlue64Icon from "app/assets/images/icons/boatServices/blue64/upholsteryCanvas.svg";
import FluidSystemsBlue64Icon from "app/assets/images/icons/boatServices/blue64/fluidSystems.svg";
import PartsRequestsBlue64Icon from "app/assets/images/icons/boatServices/blue64/partsRequests.svg";
import SpringCommissioningBlue64Icon from "app/assets/images/icons/boatServices/blue64/springCommissioning.svg";
import WinterizationBlue64Icon from "app/assets/images/icons/boatServices/blue64/winterization.svg";
import PlaceholderBlue64Icon from "app/assets/images/icons/boatServices/blue64/placeholder.svg";

// Black icons 32
import GeneralMaintenanceBlack32Icon from "app/assets/images/icons/boatServices/black32/generalMaintenance.svg";
import MotorServicesBlack32Icon from "app/assets/images/icons/boatServices/black32/motorServices.svg";
import ElectronicRepairBlack32Icon from "app/assets/images/icons/boatServices/black32/electronicRepair.svg";
import ControlSystemsBlack32Icon from "app/assets/images/icons/boatServices/black32/controlSystems.svg";
import SurveyingBlack32Icon from "app/assets/images/icons/boatServices/black32/surveying.svg";
import HullFiberglassBlack32Icon from "app/assets/images/icons/boatServices/black32/hullFiberglass.svg";
import BodyWorkBlack32Icon from "app/assets/images/icons/boatServices/black32/bodyWork.svg";
import UpholsteryCanvasBlack32Icon from "app/assets/images/icons/boatServices/black32/upholsteryCanvas.svg";
import FluidSystemsBlack32Icon from "app/assets/images/icons/boatServices/black32/fluidSystems.svg";
import PartsRequestsBlack32Icon from "app/assets/images/icons/boatServices/black32/partsRequests.svg";
import SpringCommissioningBlack32Icon from "app/assets/images/icons/boatServices/black32/springCommissioning.svg";
import WinterizationBlack32Icon from "app/assets/images/icons/boatServices/black32/winterization.svg";
import PlaceholderBlack32Icon from "app/assets/images/icons/boatServices/black32/placeholder.svg";

interface BoatServices {
  name: string;
  blue64Icon: string;
  black32Icon: string;
  iconAlt: string;
}

const boatServices: { [key: string]: BoatServices } = {
  generalMaintenance: {
    name: "General Maintenance",
    blue64Icon: GeneralMaintenanceBlue64Icon,
    black32Icon: GeneralMaintenanceBlack32Icon,
    iconAlt: "Wrench icon",
  },
  motorServices: {
    name: "Motor Services",
    blue64Icon: MotorServicesBlue64Icon,
    black32Icon: MotorServicesBlack32Icon,
    iconAlt: "Motor icon",
  },
  electronicRepair: {
    name: "Electronic Repair",
    blue64Icon: ElectronicRepairBlue64Icon,
    black32Icon: ElectronicRepairBlack32Icon,
    iconAlt: "Electronical wiring icon",
  },
  controlSystems: {
    name: "Control Systems",
    blue64Icon: ControlSystemsBlue64Icon,
    black32Icon: ControlSystemsBlack32Icon,
    iconAlt: "Control panel with map navigation icon",
  },
  surveying: {
    name: "Surveying",
    blue64Icon: SurveyingBlue64Icon,
    black32Icon: SurveyingBlack32Icon,
    iconAlt: "Monitor screen icon",
  },
  hullFiberglass: {
    name: "Hull & Fiberglass",
    blue64Icon: HullFiberglassBlue64Icon,
    black32Icon: HullFiberglassBlack32Icon,
    iconAlt: "Detailing tool icon",
  },
  bodyWork: {
    name: "Body Work",
    blue64Icon: BodyWorkBlue64Icon,
    black32Icon: BodyWorkBlack32Icon,
    iconAlt: "Paintbrush icon",
  },
  upholsteryCanvas: {
    name: "Upholstery & Canvas",
    blue64Icon: UpholsteryCanvasBlue64Icon,
    black32Icon: UpholsteryCanvasBlack32Icon,
    iconAlt: "Vinyl wrap icon",
  },
  fluidSystems: {
    name: "Fluid Systems",
    blue64Icon: FluidSystemsBlue64Icon,
    black32Icon: FluidSystemsBlack32Icon,
    iconAlt: "Hydraulic pump icon",
  },
  partsRequest: {
    name: "Parts Request",
    blue64Icon: PartsRequestsBlue64Icon,
    black32Icon: PartsRequestsBlack32Icon,
    iconAlt: "Trolley with a box icon",
  },
  springCommissioning: {
    name: "Spring Commissioning",
    blue64Icon: SpringCommissioningBlue64Icon,
    black32Icon: SpringCommissioningBlack32Icon,
    iconAlt: "Moor icon",
  },
  winterization: {
    name: "Winterization",
    blue64Icon: WinterizationBlue64Icon,
    black32Icon: WinterizationBlack32Icon,
    iconAlt: "Snowflake icon",
  },
};

export const getBoatServices = (boatService: any) => {
  const boatServiceHash = {
    blue64Icon: PlaceholderBlue64Icon,
    black32Icon: PlaceholderBlack32Icon,
    name: titleize(boatService),
    iconAlt: "Clipboard list icon",
  };

  if (boatServices[boatService]) {
    boatServiceHash.blue64Icon = boatServices[boatService].blue64Icon;
    boatServiceHash.black32Icon = boatServices[boatService].black32Icon;
    boatServiceHash.iconAlt = boatServices[boatService].iconAlt;
    boatServiceHash.name = boatServices[boatService].name;
  }

  return boatServiceHash;
};
