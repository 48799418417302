import { locationSearchTrack } from "../track";

export const locationSearchHelper = (
  searchedAddress: string,
  coordinates: { lat: string; lng: string }
) => {
  const searchedAddressArray = searchedAddress.split(", ");
  switch (searchedAddress.split.length) {
    case 2:
      // Region search
      locationSearchTrack({
        region: searchedAddressArray[0],
        country: searchedAddressArray[1],
        coordinates: {
          lat: Number(coordinates.lat),
          lng: Number(coordinates.lng),
        },
      });
      break;
    case 3:
      // City search
      locationSearchTrack({
        city: searchedAddressArray[0],
        region: searchedAddressArray[1],
        country: searchedAddressArray[2],
        coordinates: {
          lat: Number(coordinates.lat),
          lng: Number(coordinates.lng),
        },
      });
      break;
    case 4:
      // Postal Code search
      locationSearchTrack({
        postalCode: searchedAddressArray[0],
        city: searchedAddressArray[1],
        region: searchedAddressArray[2],
        country: searchedAddressArray[3],
        coordinates: {
          lat: Number(coordinates.lat),
          lng: Number(coordinates.lng),
        },
      });
  }
};
