import * as React from "react";
import { Link, Navigate } from "react-router-dom";
import { Path } from "app/Path";
import { useMechanicFavorites } from "./useMechanicFavorites";
import { FavoriteHeart } from "app/components/favoriteHeart/favoriteHeart";
import { titleize } from "app/utils/titleize";
import { capitalize, compact, startCase } from "lodash";
import { Dropdown, Spinner, TextInput } from "flowbite-react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { listMotionItem, listMotionContainer } from "app/motion/listMotion";
import { createMechanicPageUrl } from "app/utils/urls";
import { Footer } from "app/components/footer/footer";
import MechanicPlaceholder from "app/assets/images/mechanicPlaceholder.png";

export interface MehanicFavoritesProps {}

export const MehanicFavorites: React.FC<MehanicFavoritesProps> = (props) => {
  const {
    listFavorites,
    currentFavoriteList,
    onFavoriteClick,
    onUnfavoriteClick,
    isListBeingEditted,
    setIsListBeingEditted,
    onListEditSubmit,
    onDeleteListClick,
  } = useMechanicFavorites();

  if (!listFavorites && !currentFavoriteList) {
    return (
      <div className="h-screen">
        <Spinner size={"lg"} className="absolute top-1/2 left-1/2" />
      </div>
    );
  }

  if (!currentFavoriteList) {
    return <Navigate to={Path.MECHANICS_FAVORITES} replace={true} />;
  }

  return (
    <>
      <section className="flex flex-col mt-4 max-w-5xl w-full m-auto p-6">
        <div className="flex mb-6 items-center">
          <p className="text-xl mr-2 font-medium">
            {isListBeingEditted ? (
              <form onSubmit={onListEditSubmit}>
                <TextInput
                  name="favoriteListName"
                  sizing={"md"}
                  defaultValue={currentFavoriteList.name}
                />
              </form>
            ) : (
              currentFavoriteList.name
            )}
          </p>
          <Dropdown
            arrowIcon={false}
            inline={true}
            label={<PencilIcon className="h-4 w-4 text-gray-900" />}
          >
            <Dropdown.Item onClick={() => setIsListBeingEditted(true)}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              className="w-full py-2 px-4"
              onClick={() => onDeleteListClick()}
            >
              Delete
            </Dropdown.Item>
          </Dropdown>
        </div>
        <motion.ul
          className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:gap-8"
          variants={listMotionContainer}
          initial="hidden"
          animate="visible"
        >
          {listFavorites.map(
            ({ id, name, favoritable, favoritor }: any, index: number) => (
              <motion.li key={favoritable.id} variants={listMotionItem}>
                <Link
                  to={`${Path.MECHANICS}/${createMechanicPageUrl(favoritable)}`}
                  className="flex items-center p-4 bg-white border border-gray-200 rounded shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div className="flex mr-4 items-start space-x-4">
                    <img
                      className="w-8 h-8 rounded-full"
                      src={favoritable.logo || MechanicPlaceholder}
                      alt={`${favoritable.name} logo`}
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                      {startCase(favoritable.name)}
                    </p>
                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                      {compact([
                        startCase(favoritable.addresses[0].addressLine1),
                        startCase(favoritable.addresses[0].addressLine2),
                        capitalize(favoritable.addresses[0].city),
                        capitalize(favoritable.addresses[0].state),
                      ]).join(", ")}
                    </p>
                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                      {startCase(favoritable.addresses[0]?.address_line_1)}
                    </p>
                    <p className="text-sm text-gray-900 truncate dark:text-white">
                      {favoritable.providedServices.length ? (
                        favoritable.providedServices
                          .map((service: any) => titleize(service.name))
                          .join(", ")
                      ) : (
                        <div className="invisible">placeholder</div>
                      )}
                    </p>
                  </div>
                  <FavoriteHeart
                    isFavorited
                    onFavoriteClick={(event: any) =>
                      onFavoriteClick(event, { favoritor, favoritable })
                    }
                    onUnfavoriteClick={(event: any) =>
                      onUnfavoriteClick(event, { id, name })
                    }
                  />
                </Link>
              </motion.li>
            )
          )}
        </motion.ul>
      </section>
      <Footer />
    </>
  );
};
