import { Path } from "app/Path";
import { geoCoderFromAddress } from "app/utils/geocoder";
import { compact } from "lodash";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectMechanicAreas } from "app/redux/mechanicAreasSlice";
import { AppDispatch } from "app/store";
import { getSearchedMechanics } from "app/redux/searchMechanicsSlice";
import { toast, ToastType } from "app/utils/toast";
import { locationSearchTrack } from "app/utils/track/track";

export const useFooter = () => {
  const mechanicAreas = useSelector(selectMechanicAreas);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const locationSearch = async (value: string) => {
    try {
      const { city, state, country, postalCode, results } =
        await geoCoderFromAddress(value);

      try {
        await dispatch(
          getSearchedMechanics({
            searchQuery: compact([postalCode, city, state, country]).join(", "),
            searchType: "address",
          })
        ).unwrap();
        locationSearchTrack({
          city,
          region: state,
          country,
          postalCode,
          coordinates: {
            lat: results[0].geometry.location.lat,
            lng: results[0].geometry.location.lng,
          },
        });
      } catch (error: any) {
        toast(
          ToastType.ERROR,
          error?.data?.error || "Something went wrong while searching mechanics"
        );
      } finally {
        navigate(
          `${Path.MECHANICS}?${createSearchParams({
            address: compact([postalCode, city, state, country]).join(", "),
            lat: results[0].geometry.location.lat,
            lng: results[0].geometry.location.lng,
          })}`
        );
      }

      document.getElementById("mechanicList")?.scrollTo(0, 0);
    } catch (error) {
      console.log(error);
    }
  };

  return { mechanicAreas, locationSearch };
};
