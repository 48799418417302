import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import http from "app/requests/axiosInstance";

interface mechanicFavortesState {
  favoriteLists: [];
  pagy: any;
}

// Define the initial state using that type
const initialState = {
  favoriteLists: [],
  pagy: {},
} as mechanicFavortesState;

export const getMechanicFavoriteLists = createAsyncThunk(
  "mechanics/getMechanicFavoriteListsStatus",
  async ({}: any, thunkAPI) => {
    try {
      const { data }: any = await http.get("/v1/favorite_lists");
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const createMechanicFavoriteList = createAsyncThunk(
  "mechanics/createMechanicFavoriteListStatus",
  async ({ name, description }: any, thunkAPI) => {
    try {
      const { data }: any = await http.post("/v1/favorite_lists", {
        name,
        description,
      });
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const editMechanicFavoriteList = createAsyncThunk(
  "mechanics/editMechanicFavoriteListStatus",
  async ({ id, name, description }: any, thunkAPI) => {
    try {
      const { data }: any = await http.put(`/v1/favorite_lists/${id}`, {
        name,
        description,
      });
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteMechanicFavoriteList = createAsyncThunk(
  "mechanics/deleteMechanicFavoriteListStatus",
  async (id: number, thunkAPI) => {
    try {
      const { data }: any = await http.delete(`/v1/favorite_lists/${id}`);
      thunkAPI.dispatch(getMechanicFavoriteLists({}) as any);
      return data;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const mechanicFavoritesListSlice = createSlice({
  name: "mechanicfavoritesList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(
      getMechanicFavoriteLists.fulfilled,
      (state: any, action) => {
        return Object.assign({}, state.favorites, action.payload);
      }
    );
    builder.addCase(
      createMechanicFavoriteList.fulfilled,
      (state: any, action) => {
        let newState = { ...state };
        newState = {
          ...state,
          favoriteLists: [...state.favoriteLists, action.payload],
        };
        return newState;
      }
    );
    builder.addCase(
      editMechanicFavoriteList.fulfilled,
      (state: any, action) => {
        let newState = { ...state };
        const newFavoriteLists = newState.favoriteLists.map((list: any) =>
          list.id === action.payload.id ? action.payload : list
        );
        newState.favoriteLists = newFavoriteLists;
        return newState;
      }
    );
    builder.addCase(
      deleteMechanicFavoriteList.fulfilled,
      (state: any, action) => {}
    );
  },
});

export const selectMechanicFavoritesList = (state: RootState) =>
  state.mechanicFavoritesList;

export default mechanicFavoritesListSlice.reducer;
