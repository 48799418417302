import { Path } from "app/Path";
import { geoCoderFromAddress } from "app/utils/geocoder";
import { compact } from "lodash";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectMechanicAreas } from "app/redux/mechanicAreasSlice";
import { locationSearchTrack } from "app/utils/track/track";

export enum MechanicAreasCategory {
  TOP = "top",
  NEARBY = "nearby",
}

export const mechanicAreasList = (
  category: MechanicAreasCategory,
  mechanicAreas: any
) => {
  switch (category) {
    case MechanicAreasCategory.TOP:
      return mechanicAreas.top;
    case MechanicAreasCategory.NEARBY:
      return mechanicAreas.nearby?.length
        ? mechanicAreas.nearby
        : mechanicAreas.top;
    default:
      return mechanicAreas?.top;
  }
};

export const useMechanicAreas = () => {
  const navigate = useNavigate();
  const mechanicAreas = useSelector(selectMechanicAreas);

  const locationSearch = async (value: string) => {
    try {
      const { city, state, country, postalCode, results } =
        await geoCoderFromAddress(value);

      navigate(
        `${Path.MECHANICS}?${createSearchParams({
          address: compact([postalCode, city, state, country]).join(", "),
          lat: results[0].geometry.location.lat,
          lng: results[0].geometry.location.lng,
        })}`
      );

      locationSearchTrack({
        city,
        region: state,
        country,
        postalCode,
        coordinates: {
          lat: results[0].geometry.location.lat,
          lng: results[0].geometry.location.lng,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return { mechanicAreas, locationSearch };
};
