import { Path } from "app/Path";
import { selectIpData } from "app/redux/ipDataSlice";
import {
  getSearchedMechanics,
  selectSearchMechanics,
} from "app/redux/searchMechanicsSlice";
import { AppDispatch } from "app/store";
import { ToastType, toast } from "app/utils/toast";
import { useQuery } from "app/utils/useQuery";
import { snakeCase } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export const useMechanicList = () => {
  const query = useQuery();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const { mechanicAccounts, pagy } = useSelector(selectSearchMechanics);
  let { service: serviceParam } = useParams();
  const service = serviceParam?.includes("services")
    ? serviceParam.split("-")[0]
    : serviceParam;
  const navigate = useNavigate();
  const ipData = useSelector(selectIpData);

  const getMechanicsWithServies = async (page?: any) => {
    try {
      setLoading(true);
      if (ipData || page) {
        const { mechanicAccounts: mechanics } = await dispatch(
          getSearchedMechanics({
            searchQuery: `${ipData.latitude},${ipData.longitude}`,
            searchType: "coordinates",
            page: page || 1,
            providedServices: [snakeCase(serviceParam)],
            snakeCase: true,
          })
        ).unwrap();
        if (mechanics.length === 0) {
          dispatch(
            getSearchedMechanics({
              page: page || 1,
              providedServices: [snakeCase(serviceParam)],
              snakeCase: true,
            })
          ).unwrap();
        }
      }
    } catch (error) {
      console.log(error);
      toast(ToastType.ERROR, "Something went wrong with fetching mechanics");
    } finally {
      setLoading(false);
    }
  };

  const onPagechange = async (page: number) => {
    if (Number(query.get("page")) === page) {
      return;
    }
    try {
      navigate(`${Path.BOATSERVICES}/${serviceParam}?page=${page}`);
      await getMechanicsWithServies(page);
      window.scrollTo(0, 0);
    } catch {
      toast(ToastType.ERROR, "Something went wrong with fetching mechanics");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await getMechanicsWithServies();
    })();
  }, [ipData]);

  return { mechanicAccounts, service, pagy, loading, onPagechange, ipData };
};
