import http from "app/requests/axiosInstance";
import { useState, useEffect } from "react";

export const useBlogList = () => {
  const [loading, setLoading] = useState(false);
  const [blogList, setBlogList] = useState<any>(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data }: any = await http.get(`/v1/blogs`);
        setBlogList(data);
        window.scrollTo(0, 0);
      } catch (error: any) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    loading,
    blogList,
  };
};
