import * as React from "react";
import { Navigate } from "react-router-dom";
import { toast, ToastType } from "app/utils/toast";
import { LOCAL_STORAGE } from "app/redux/usersSlice";

export interface ProtectedRouteProps {
  children?: any;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  if (!localStorage.getItem(LOCAL_STORAGE.FMBM_USER)) {
    toast(ToastType.INFO, "You must be logged in to add favorites.");
    return <Navigate to="/?auth=login" />;
  }

  return children;
};
