import { convertDistance, getDistance } from "geolib";
import { find, round, set } from "lodash";
import { AppDispatch } from "app/store";
import { batch, useDispatch, useSelector } from "react-redux";
import { useQuery } from "app/utils/useQuery";
import {
  getSearchedMechanics,
  selectSearchMechanics,
} from "app/redux/searchMechanicsSlice";
import {
  addMechanicFavorite,
  removeMechanicFavorite,
  selectMechanicFavorites,
} from "app/redux/mechanicFavoritesSlice";
import { toast, ToastType } from "app/utils/toast";
import { useEffect, useState } from "react";
import { getFormValues } from "app/utils/getFormValues";
import { createMechanicFavoriteList } from "app/redux/mechanicFavoritesListSlice";
import { selectUser } from "app/redux/usersSlice";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { Path } from "app/Path";
import { getReverseGeocode } from "app/utils/geocoder";
import {
  favoritedMechanicTrack,
  unfavoritedMechanicTrack,
} from "app/utils/track/track";

export const useMechanicList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { mechanicAccounts, pagy } = useSelector(selectSearchMechanics);
  const favorites = useSelector(selectMechanicFavorites);
  const user = useSelector(selectUser);
  const query = useQuery();
  const addressParam = query.get("address");
  const navigate = useNavigate();
  const searchedCoordinates = {
    lat: query.get("lat") || "",
    lng: query.get("lng") || "",
  };
  const [isFavoriteModalOpen, setIsFavoriteModalOpen] = useState(false);
  const [mechanicToBeFavorited, setMechanicToBeFavorited] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const mostlikelyARegion = addressParam?.split(",").length === 2;
  const [listLoading, setListLoading] = useState(true);
  const [hoveredMarkerId, setHoveredMarkerId] = useState<any>(null);
  const [sliderDelta, setSliderDelta] = useState<any>(0);
  const [currentCountry, setCurrentCountry] = useState<any>(null);

  const distanceFromSearched = (start: any, end: any) => {
    const distance_in_meters = getDistance(
      {
        latitude: start.lat,
        longitude: start.lng,
      },
      {
        latitude: end.lat,
        longitude: end.lng,
      }
    );

    let covertedDistance = "";

    if (distance_in_meters < 1000) {
      covertedDistance = `${round(distance_in_meters, 1)} m`;
    } else if (currentCountry === "United States") {
      covertedDistance = `${round(
        convertDistance(distance_in_meters, "mi"),
        1
      )} mi`;
    } else {
      covertedDistance = `${round(
        convertDistance(distance_in_meters, "km"),
        1
      )} km`;
    }
    return covertedDistance;
  };

  const handleFavoriteClick = (event: any, mechanicAccount: any) => {
    event.preventDefault();
    if (!user.id) {
      toast(ToastType.INFO, "You must be logged in to add favorites.");
      query.append("auth", "login");
      navigate({
        pathname: window.location.pathname,
        search: query.toString(),
      });
      return;
    }
    const favoritedMechanicAccount: any = find(
      favorites,
      (fav) => fav.favoritable.id === mechanicAccount.id
    );
    if (favoritedMechanicAccount) {
      onUnfavoriteClick(favoritedMechanicAccount.id);
    } else {
      setIsFavoriteModalOpen(true);
      setMechanicToBeFavorited(mechanicAccount);
    }
  };

  const onFavoriteWithNewList = async (event: any) => {
    event.preventDefault();
    const { newListName } = getFormValues(event.target);
    try {
      await batch(async () => {
        const newCreateList = await dispatch(
          createMechanicFavoriteList({
            name: newListName,
          }) as any
        ).unwrap();
        await dispatch(
          addMechanicFavorite({
            favoritorId: newCreateList.id,
            favoritableId: mechanicToBeFavorited.id,
          }) as any
        ).unwrap();
      });
      setIsFavoriteModalOpen(false);
      toast(ToastType.SUCCESS, `Mechanic added to ${newListName} list`);
      favoritedMechanicTrack({
        id: mechanicToBeFavorited.id,
        name: mechanicToBeFavorited.name,
        address: {
          city: mechanicToBeFavorited.addresses[0].city,
          region: mechanicToBeFavorited.addresses[0].region,
          country: mechanicToBeFavorited.addresses[0].country,
          postalCode: mechanicToBeFavorited.addresses[0].postalCode,
          coordinates: {
            lat: mechanicToBeFavorited.addresses[0].latitude,
            lng: mechanicToBeFavorited.addresses[0].longitude,
          },
        },
      });
    } catch (error: any) {
      console.log(error);
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong with adding favorite"
      );
    }
  };

  const handleFavoriteListClick = (favoriteList: any) => {
    onFavoriteSubmit({
      favoritor: favoriteList,
      favoritable: mechanicToBeFavorited,
    });
  };

  const onFavoriteSubmit = async ({ favoritor, favoritable }: any) => {
    try {
      const { favoritable: newFavoritable } = await dispatch(
        addMechanicFavorite({
          favoritorId: favoritor.id,
          favoritableId: favoritable.id,
        }) as any
      ).unwrap();
      setIsFavoriteModalOpen(false);
      toast(ToastType.SUCCESS, `Mechanic added to ${favoritor.name} list`);
      favoritedMechanicTrack({
        id: newFavoritable.id,
        name: newFavoritable.name,
        address: {
          city: newFavoritable.addresses[0].city,
          region: newFavoritable.addresses[0].region,
          country: newFavoritable.addresses[0].country,
          postalCode: newFavoritable.addresses[0].postalCode,
          coordinates: {
            lat: newFavoritable.addresses[0].latitude,
            lng: newFavoritable.addresses[0].longitude,
          },
        },
      });
    } catch (error: any) {
      console.log(error);
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong with adding favorite"
      );
    }
  };

  const onUnfavoriteClick = async (favoriteId: any) => {
    try {
      const { favoritable } = await dispatch(
        removeMechanicFavorite(favoriteId) as any
      ).unwrap();
      toast(
        ToastType.SUCCESS,
        `Mechanic has been remove from your favorite list`
      );

      unfavoritedMechanicTrack({
        id: favoritable.id,
        name: favoritable.name,
        address: {
          city: favoritable.addresses[0].city,
          region: favoritable.addresses[0].region,
          country: favoritable.addresses[0].country,
          postalCode: favoritable.addresses[0].postalCode,
          coordinates: {
            lat: favoritable.addresses[0].latitude,
            lng: favoritable.addresses[0].longitude,
          },
        },
      });
    } catch (error: any) {
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong with removing favorite"
      );
    }
  };

  const onPagechange = async (page: number) => {
    if (Number(query.get("page")) === page) {
      return;
    }
    try {
      setListLoading(true);
      await dispatch(
        getSearchedMechanics({
          searchQuery:
            addressParam ||
            `${searchedCoordinates.lat},${searchedCoordinates.lng}`,
          searchType: addressParam ? "address" : "coordinates",
          page,
        })
      ).unwrap();
      navigate(
        `${Path.MECHANICS}?${createSearchParams({
          address: addressParam?.toString() || "",
          lat: searchedCoordinates.lat.toString(),
          lng: searchedCoordinates.lng.toString(),
          page: page.toString(),
        })}`
      );
      document.getElementById("mechanicList")?.scrollTo(0, 0);
    } catch {
      toast(ToastType.ERROR, "Something went wrong with fetching mechanics");
    } finally {
      setListLoading(false);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedUp: (eventData) => {
      if (sliderDelta === 0) {
        setSliderDelta(270);
      }
    },
    onSwipedDown: (eventData) => {
      if (sliderDelta === 270) {
        setSliderDelta(0);
      }
    },
    preventScrollOnSwipe: true,
  });

  useEffect(() => {
    (async () => {
      if (
        addressParam ||
        (searchedCoordinates.lat && searchedCoordinates.lng)
      ) {
        try {
          setLoading(true);
          await dispatch(
            getSearchedMechanics({
              searchQuery:
                addressParam ||
                `${searchedCoordinates.lat},${searchedCoordinates.lng}`,
              searchType: addressParam ? "address" : "coordinates",
              page: Number(query.get("page")) || 1,
            })
          ).unwrap();
        } catch {
          toast(
            ToastType.ERROR,
            "Something went wrong with fetching mechanics"
          );
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const locationData = await getReverseGeocode(
        searchedCoordinates.lng,
        searchedCoordinates.lat
      );

      const country = find(
        locationData.features,
        (data: any) => data["placeType"][0] == "country"
      );

      setCurrentCountry(country.placeName);
    })();
  }, [searchedCoordinates]);

  return {
    mechanicAccounts,
    pagy,
    searchedCoordinates,
    distanceFromSearched,
    favorites,
    setIsFavoriteModalOpen,
    onUnfavoriteClick,
    isFavoriteModalOpen,
    handleFavoriteClick,
    handleFavoriteListClick,
    onFavoriteWithNewList,
    loading,
    mostlikelyARegion,
    onPagechange,
    listLoading,
    hoveredMarkerId,
    setHoveredMarkerId,
    swipeHandlers,
    sliderDelta,
    addressParam,
    currentCountry,
  };
};
