import { useState } from "react";
import { selectMechanicFavoritesList } from "app/redux/mechanicFavoritesListSlice";
import { useSelector } from "react-redux";

export const useFavoritesModal = () => {
  const { favoriteLists } = useSelector(selectMechanicFavoritesList);
  const [showFavoritePage, setShowFavoritePage] = useState(false);

  return {
    favoriteLists,
    showFavoritePage,
    setShowFavoritePage,
  };
};
