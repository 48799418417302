import { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { compact, debounce } from "lodash";
import { geoCoderFromAddress } from "app/utils/geocoder";
import { useDispatch } from "react-redux";
import { getSearchedMechanics } from "app/redux/searchMechanicsSlice";
import { AppDispatch } from "app/store";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useQuery } from "app/utils/useQuery";
import { Path } from "app/Path";
import { toast, ToastType } from "app/utils/toast";
import { locationSearchHelper } from "app/utils/track/utils/locationSearchHelper";

export const useSearchmechanics = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const query = useQuery();
  const addressParam = query.get("address");
  const [searchedAddress, setSearchedAddress] = useState<any | string>(
    addressParam || ""
  );
  const [searchedCoordinates, setSearchedCoordinates] = useState<any | null>({
    lat: query.get("lat"),
    lng: query.get("lng"),
  });
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState(addressParam || ("" as string));
  const onInputFocus = (isFocused: boolean) => {
    setIsInputFocused(isFocused);
  };
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);

  const locationSearch = async (value: string) => {
    try {
      const { city, state, country, postalCode, results } =
        await geoCoderFromAddress(value);

      const formattedAddress = compact([postalCode, city, state, country]).join(
        ", "
      );
      setSearchedAddress(formattedAddress);
      setSearchedCoordinates({
        lat: results[0].geometry.location.lat,
        lng: results[0].geometry.location.lng,
      });

      setShowSearchDropdown(true);
    } catch (error) {
      console.log(error);
      inputValue.length < 3 && setSearchedAddress("");
    }
  };

  const debouncedLocationSearch = debounce(
    async (value: string) => locationSearch(value),
    100
  );

  const handleOnChangeMechanicSearch = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setInputValue(event.target.value);
    debouncedLocationSearch(event.target.value);
  };

  const onSubmit = async (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    if (!searchedAddress) return;
    setInputValue(searchedAddress);
    try {
      await dispatch(
        getSearchedMechanics({
          searchQuery: searchedAddress,
          searchType: "address",
        })
      ).unwrap();

      navigate(
        `${Path.MECHANICS}?${createSearchParams({
          address: searchedAddress,
          ...searchedCoordinates,
        })}`
      );

      setShowSearchDropdown(false);

      locationSearchHelper(searchedAddress, searchedCoordinates);
    } catch (error: any) {
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong while searching mechanics"
      );
    } finally {
      document.getElementById("mechanicList")?.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (addressParam) {
      setSearchedAddress(addressParam);
      setInputValue(addressParam);
    } else {
      setSearchedAddress("");
      setInputValue("");
    }
  }, [addressParam]);

  return {
    handleOnChangeMechanicSearch,
    searchedAddress,
    setSearchedAddress,
    onSubmit,
    onInputFocus,
    isInputFocused,
    inputValue,
    setInputValue,
    showSearchDropdown,
    addressParam,
    locationSearch,
    searchedCoordinates,
  };
};
