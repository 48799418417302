import { FavoriteHeart } from "app/components/favoriteHeart/favoriteHeart";
import { FavoritesModal } from "app/components/favoritesModal/favoritesModal";
import { Spinner } from "flowbite-react";
import * as React from "react";
import { Contact } from "./components/contact/contact";
import { Services } from "./components/services/services";
import { useMechanicShow } from "./useMechanicShow";
import { Helmet } from "react-helmet";
import MechanicPlaceholder from "app/assets/images/mechanicPlaceholder.png";
import { isMobileOnly } from "react-device-detect";
import { Footer } from "app/components/footer/footer";
import MechBanner0 from "app/assets/images/mechanicBannerPlaceholders/0.jpg";
import MechBanner1 from "app/assets/images/mechanicBannerPlaceholders/1.jpg";
import MechBanner2 from "app/assets/images/mechanicBannerPlaceholders/2.jpg";
import MechBanner3 from "app/assets/images/mechanicBannerPlaceholders/3.jpg";
import MechBanner4 from "app/assets/images/mechanicBannerPlaceholders/4.jpg";
import MechBanner5 from "app/assets/images/mechanicBannerPlaceholders/5.jpg";
import MechBanner6 from "app/assets/images/mechanicBannerPlaceholders/6.jpg";
import MechBanner7 from "app/assets/images/mechanicBannerPlaceholders/7.jpg";
import MechBanner8 from "app/assets/images/mechanicBannerPlaceholders/8.jpg";
import MechBanner9 from "app/assets/images/mechanicBannerPlaceholders/9.jpg";
import MechBanner10 from "app/assets/images/mechanicBannerPlaceholders/10.jpg";
import { Reviews } from "./components/reviews/reviews";

const mechanicBanners = {
  0: MechBanner0,
  1: MechBanner1,
  2: MechBanner2,
  3: MechBanner3,
  4: MechBanner4,
  5: MechBanner5,
  6: MechBanner6,
  7: MechBanner7,
  8: MechBanner8,
  9: MechBanner9,
  10: MechBanner10,
} as any;

export interface MechanicShowProps {}

export const MechanicShow: React.FC<MechanicShowProps> = (props) => {
  const {} = props;
  const {
    mechanicAccount,
    currentFavorite,
    onUnfavoriteClick,
    isFavoriteModalOpen,
    setIsFavoriteModalOpen,
    handleFavoriteListClick,
    onFavoriteWithNewList,
    handleFavoriteClick,
    noServices,
    meta,
    bannerId,
  } = useMechanicShow();
  const banner = mechanicBanners[bannerId.current];

  if (!mechanicAccount.id)
    return <Spinner size={"lg"} className="absolute top-1/2 left-1/2" />;

  return (
    <>
      <div className="m-auto mt-4 mb-6 px-6 max-w-5xl">
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:description" content={meta.description} />
          <link rel="canonical" href={window.location.href} />
          <meta name="keywords" content={meta.meta.name.keywords} />
        </Helmet>
        <FavoritesModal
          isOpen={isFavoriteModalOpen}
          toggleOpen={setIsFavoriteModalOpen}
          handleFavoriteListClick={handleFavoriteListClick}
          onFavoriteWithNewList={onFavoriteWithNewList}
        />
        <section
          className={
            "flex relative flex-col items-center rounded justify-center my-4 py-4 bg-center bg-cover w-full"
          }
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(229, 231, 235, 0),rgba(35, 53, 92, 0.82)), url(${banner})`,
          }}
        >
          <div className="flex flex-col justify-between">
            <img
              src={mechanicAccount?.logo || MechanicPlaceholder}
              alt={
                mechanicAccount?.logo
                  ? `${mechanicAccount.name} logo`
                  : `placeholder logo for ${mechanicAccount.name}`
              }
              className="w-24	h-24 mx-auto mb-4 rounded-full"
            />
            <div className="flex relative justify-between text-white">
              <p>{mechanicAccount.name}</p>
              {!isMobileOnly && (
                <div className="absolute -right-8">
                  <FavoriteHeart
                    white
                    isFavorited={!!currentFavorite}
                    onFavoriteClick={handleFavoriteClick}
                    onUnfavoriteClick={() =>
                      onUnfavoriteClick(currentFavorite!.id)
                    }
                  />
                </div>
              )}
            </div>
            {isMobileOnly && (
              <div className="absolute top-4 right-4">
                <FavoriteHeart
                  white
                  isFavorited={!!currentFavorite}
                  onFavoriteClick={handleFavoriteClick}
                  onUnfavoriteClick={() =>
                    onUnfavoriteClick(currentFavorite!.id)
                  }
                />
              </div>
            )}
          </div>
        </section>
        <div className="flex flex-col w-full sm:gap-4 sm:flex-row">
          <div className={"w-full md:w-3/4"}>
            <Contact mechanicAccount={mechanicAccount} />
            {!isMobileOnly && (
              <a
                className="btn-outline block mt-4 w-full text-center py-2.5 px-5 text-sm font-medium text-gray-900 md:right-2 focus:outline-none"
                href={`mailto:al@tacit.ventures?subject=Hi, I would like to claim my business on findmyboatmechanic.com - ${mechanicAccount.name}`}
              >
                Your business? Claim it for additional features
              </a>
            )}
          </div>
          {noServices ? (
            <div className="flex justify-center w-full rounded rounded-b-lg bg-white border p-4 divide-y divide-gray-200 dark:divide-gray-700 sm:rounded">
              <p className="text-lg w-full font-medium mt-7 text-gray-900 dark:text-white">
                No services provided yet, we are working on this
              </p>
            </div>
          ) : (
            <div className="w-full rounded flex flex-col sm:gap-4">
              <Services mechanicAccount={mechanicAccount} />
              {mechanicAccount.reviews.length > 0 && (
                <Reviews mechanicAccount={mechanicAccount} />
              )}
            </div>
          )}
        </div>
      </div>
      {isMobileOnly && (
        <a
          className="btn-outline block w-3/4 m-auto text-center p-5 font-medium text-gray-900 focus:outline-none"
          href={`mailto:al@tacit.ventures?subject=Hi, I would like to claim my business on findmyboatmechanic.com - ${mechanicAccount.name}`}
        >
          Your business? Claim it for additional features
        </a>
      )}
      <Footer />
    </>
  );
};
