import * as React from "react";
import { Helmet } from "react-helmet";
import { BlogList } from "../components/blogList/blogList";
import { Footer } from "app/components/footer/footer";
import { Link } from "react-router-dom";
import { Path } from "app/Path";

export interface BlogIndexProps {}

export const BlogIndex: React.FC<BlogIndexProps> = () => {
  return (
    <>
      <Helmet>
        <title>{"Findmyboatmechanic's blogs list"}</title>
        <meta
          name="description"
          content={"A list blogs belong to findmyboatmechanic.com"}
        />
        <meta property="og:title" content={"Findmyboatmechanic's blogs list"} />
        <meta
          property="og:description"
          content={"A list blogs belong to findmyboatmechanic.com"}
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <section>
        <div className="px-4 mx-auto max-w-screen-xl text-center py-12">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none  md:text-5xl lg:text-6xl">
            Resources
          </h1>
          <p className="mb-8 text-lg font-normal  lg:text-xl sm:px-16">
            Master the Art of Boat Maintenance: Your Ultimate Resources to
            Caring for Your Vessel
          </p>
        </div>
      </section>
      <section className="pt-10">
        <BlogList />
      </section>
      <Footer />
    </>
  );
};
