import * as React from "react";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { ListItem } from "app/mechanic/show/components/listItem";
import { MechanicAccountsProps } from "app/mechanic/mechanicProps";
import { capitalize, compact, startCase, toUpper } from "lodash";
import { HoursOfOperation } from "./hoursOfOperation";
import { PhoneIcon } from "@heroicons/react/24/outline";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import Facebook from "app/assets/images/icons/facebook.svg";
import Instagram from "app/assets/images/icons/instagram.svg";
import Twitter from "app/assets/images/icons/twitter.svg";
import LinkedIn from "app/assets/images/icons/linkedIn.svg";
import bbbAcreditedRectangle from "app/assets/images/bbbAcreditedRectangle.png";
import abbraLogo from "app/assets/images/abbraLogo.png";
import { useContact } from "./useContact";
import { titleize } from "app/utils/titleize";

export interface ContactProps {
  mechanicAccount: MechanicAccountsProps;
}

export const Contact: React.FC<ContactProps> = (props) => {
  const {
    mechanicAccount: {
      name,
      addresses,
      hoursOfOperation,
      contact,
      betterBusinessBureau,
      mobileService,
      abbra,
    },
  } = props;
  const { trackIntention } = useContact();

  return (
    <div className="flex flex-col h-fit rounded gap-8 bg-white border p-4 sm:rounded">
      <ListItem
        icon={<MapPinIcon className="h-[20px] w-[20px] text-gray-900" />}
        customContent={
          !mobileService ? (
            <p>
              {compact([
                startCase(addresses[0].addressLine1),
                startCase(addresses[0].addressLine2),
                capitalize(addresses[0].city),
                capitalize(addresses[0].state),
                toUpper(addresses[0].postalCode),
                capitalize(addresses[0].country),
              ]).join(", ")}
            </p>
          ) : (
            <p>
              {name} is a mobile service that services areas of{" "}
              {mobileService.addresses.map(
                (address: any, index: number, self: any) => (
                  <span key={address.id}>
                    {titleize(address.city)}
                    {index !== self.length - 1 ? "," : ""}{" "}
                  </span>
                )
              )}
            </p>
          )
        }
        removeListGap
      />
      <HoursOfOperation hoursOfOperation={hoursOfOperation} />
      <ListItem
        icon={<PhoneIcon className="h-[20px] w-[20px]" />}
        customContent={
          contact?.phone ? (
            <a
              className="text-blue-700 break-all"
              href={`tel:${contact.phone}`}
              onClick={() =>
                trackIntention({ type: "phone", value: contact.phone! })
              }
            >
              {contact.phone}
            </a>
          ) : (
            "No phone number provided"
          )
        }
        center
        removeListGap
      />
      <ListItem
        icon={<EnvelopeIcon className="h-[20px] w-[20px]" />}
        customContent={
          contact?.email ? (
            <a
              className="text-blue-700 break-all"
              href={`mailto: ${contact.email}`}
              onClick={() =>
                trackIntention({ type: "email", value: contact.email! })
              }
            >
              {contact.email}
            </a>
          ) : (
            "No email provided"
          )
        }
        center
        removeListGap
      />

      <ListItem
        icon={<ArrowTopRightOnSquareIcon className="h-[20px] w-[20px]" />}
        customContent={
          contact?.website ? (
            <a
              className="text-blue-700 break-all"
              href={contact.website}
              target="_blank"
              onClick={() =>
                trackIntention({
                  type: "website",
                  value: contact.website!,
                })
              }
            >
              {contact.website}
            </a>
          ) : (
            "No website provided"
          )
        }
        center
        removeListGap
      />

      <div className="flex gap-4">
        {contact?.facebook && (
          <a href={contact.facebook} target="_blank">
            <img
              className="cursor-pointer w-8 h-8"
              src={Facebook}
              alt="facebook icon"
              onClick={() =>
                trackIntention({
                  type: "facebook",
                  value: contact.facebook!,
                })
              }
            />
          </a>
        )}
        {contact?.twitter && (
          <a
            href={contact.twitter}
            target="_blank"
            onClick={() =>
              trackIntention({
                type: "twitter",
                value: contact.twitter!,
              })
            }
          >
            <img
              className="cursor-pointer w-8 h-8"
              src={Twitter}
              alt="twitter icon"
            />
          </a>
        )}
        {contact?.instagram && (
          <a
            href={contact.instagram}
            target="_blank"
            onClick={() =>
              trackIntention({
                type: "twitter",
                value: contact.instagram!,
              })
            }
          >
            <img
              className="cursor-pointer w-8 h-8"
              src={Instagram}
              alt="instagram icon"
            />
          </a>
        )}
        {contact?.linkedin && (
          <a
            href={contact.linkedin}
            target="_blank"
            onClick={() =>
              trackIntention({
                type: "twitter",
                value: contact.linkedin!,
              })
            }
          >
            <img
              className="cursor-pointer w-8 h-8"
              src={LinkedIn}
              alt="LinkedIn icon"
            />
          </a>
        )}
      </div>
      <div className="grid grid-cols-2 items-center justify-center">
        {betterBusinessBureau && (
          <div className="w-full flex place-content-center">
            <img
              className="w-3/4"
              src={bbbAcreditedRectangle}
              alt="Better Business Bureau Acredited"
            />
          </div>
        )}
        {abbra && (
          <div className="w-full flex place-content-center">
            <img className="w-3/4" src={abbraLogo} alt="Abbra Certified" />
          </div>
        )}
      </div>
    </div>
  );
};
