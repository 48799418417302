import { kebabCase } from "lodash";

export const createMechanicPageUrl = (mechanicAccount: any): any => {
  /* /:country/:region/:mechanicName/:id */
  const { addresses, name, id } = mechanicAccount;

  return `${kebabCase(addresses[0].country)}/${kebabCase(
    addresses[0].region
  )}/${kebabCase(name)}/${id}`;
};
