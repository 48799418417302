import {
  addMechanicFavorite,
  getMechanicFavorites,
  removeMechanicFavorite,
  selectMechanicFavorites,
} from "app/redux/mechanicFavoritesSlice";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastType } from "app/utils/toast";
import { selectUser } from "app/redux/usersSlice";
import { useQuery } from "app/utils/useQuery";
import {
  deleteMechanicFavoriteList,
  editMechanicFavoriteList,
  selectMechanicFavoritesList,
} from "app/redux/mechanicFavoritesListSlice";
import { find } from "lodash";
import { getFormValues } from "app/utils/getFormValues";
import { Path } from "app/Path";

export const useMechanicFavorites = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const { favoriteLists } = useSelector(selectMechanicFavoritesList);
  const currentFavoriteList = find(favoriteLists, { id: Number(id) }) as any;
  const favorites = useSelector(selectMechanicFavorites);
  const listFavorites = favorites.filter(
    (fav) => fav.favoritor.id === Number(id)
  );
  const user = useSelector(selectUser);
  const [isListBeingEditted, setIsListBeingEditted] = useState(false);

  const onListEditSubmit = async (event: any) => {
    event.preventDefault();
    const { favoriteListName } = getFormValues(event.target);
    try {
      await dispatch(
        editMechanicFavoriteList({
          id: Number(id),
          name: favoriteListName,
        }) as any
      ).unwrap();
      setIsListBeingEditted(false);
      toast(ToastType.SUCCESS, "Favorite list name updated");
    } catch (error: any) {
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong with updating favorite list"
      );
    }
  };

  const onDeleteListClick = async () => {
    try {
      await dispatch(deleteMechanicFavoriteList(Number(id)) as any).unwrap();
      toast(ToastType.SUCCESS, "Favorite list deleted");
      navigate(Path.MECHANICS_FAVORITES);
      toast(ToastType.SUCCESS, "Favorite list deleted");
    } catch (error: any) {
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong with deleting favorite list"
      );
    }
  };

  const onFavoriteClick = async (
    event: any,
    { favoritor, favoritable }: any
  ) => {
    event.preventDefault();
    if (!user.id) {
      toast(ToastType.INFO, "You must be logged in to add favorites.");
      query.append("auth", "login");
      navigate({
        pathname: window.location.pathname,
        search: query.toString(),
      });
      return;
    }
    try {
      await dispatch(
        addMechanicFavorite({
          favoritor: favoritor.id,
          favoritable: favoritable.id,
        }) as any
      ).unwrap();
    } catch (error: any) {
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong with adding favorite"
      );
    }
  };

  const onUnfavoriteClick = async (event: any, { id, name }: any) => {
    event.preventDefault();
    try {
      await dispatch(removeMechanicFavorite(id) as any).unwrap();
      toast(ToastType.SUCCESS, `Mechanic added to ${name} list`);
    } catch (error: any) {
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong with removing favorite"
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getMechanicFavorites(id) as any).unwrap();
      } catch (error: any) {
        toast(
          ToastType.ERROR,
          error.data.error ||
            "Something went wrong getting your favorite mechanics."
        );
      }
    })();
  }, []);

  return {
    favorites,
    currentFavoriteList,
    listFavorites,
    onFavoriteClick,
    onUnfavoriteClick,
    isListBeingEditted,
    setIsListBeingEditted,
    onListEditSubmit,
    onDeleteListClick,
  };
};
