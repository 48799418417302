import * as React from "react";
import { useBlogList } from "./useBlogList";
import { Spinner } from "flowbite-react";
import BlogThubnailPlaceholder from "app/assets/images/blogThubnailPlaceholder.jpeg";
import { Link } from "react-router-dom";
import { Path } from "app/Path";
import { motion } from "framer-motion";
import classNames from "classnames";
import { listMotionItem, listMotionContainer } from "app/motion/listMotion";
import { GhoastDiv } from "../ghostDiv";

export const BlogList: React.FC = () => {
  const { loading, blogList } = useBlogList();

  const blogs =
    window.location.pathname === Path.HOME && blogList
      ? blogList.slice(0, 3)
      : blogList;

  return (
    <motion.ul
      className={classNames(
        "flex flex-col md:grid md:grid-cols-3 gap-4 md:gap-8 m-auto md:px-4 max-w-5xl"
      )}
      variants={listMotionContainer}
      initial={loading || !blogList ? "visible" : "hidden"}
      animate="visible"
    >
      {loading || !blogList ? (
        <GhoastDiv />
      ) : (
        blogs.map((blog: any, index: number) => (
          <motion.li
            key={index}
            variants={listMotionItem}
            className="bg-white rounded overflow-hidden border-gray-100 border-2 hover:border-blue-600"
          >
            <Link to={`${Path.BLOG}/${blog.slug}`}>
              <img
                className="h-56 w-full object-cover"
                src={blog.thumbnail || BlogThubnailPlaceholder}
                alt={
                  blog.thumbnailAltText ||
                  "The boat Aquila 28 Molokai Cuddy sailing on the water"
                }
              />
              <div className="p-5">
                <p className="mb-2 dark:text-white">{blog.title}</p>
              </div>
            </Link>
          </motion.li>
        ))
      )}
    </motion.ul>
  );
};
