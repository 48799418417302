import { Path } from "app/Path";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export interface NotFoundProps {}

export const NotFound: React.FC<NotFoundProps> = (props) => {
  const {} = props;
  return (
    <section className="absolute top-1/2 -translate-y-1/2 w-full">
      <Helmet>
        <title>404 Page Not Found - Find My Boat Mechanic</title>
        <meta
          name="description"
          content="Oops! The page you're looking for can't be found. Visit findmyboatmechanic.com to search for a boat mechanic near you."
        />
        <meta
          property="og:title"
          content="404 Page Not Found - Find My Boat Mechanic"
        />
        <meta
          property="og:description"
          content="Oops! The page you're looking for can't be found. Visit findmyboatmechanic.com to search for a boat mechanic near you."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-blue-600 dark:text-blue-500">
            404
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
            Something's missing.
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
            Oops! The page you're looking for can't be found. Visit{" "}
            <Link to={Path.HOME} className="text-blue-600">
              findmyboatmechanic.com
            </Link>{" "}
            to search for a boat mechanic near you.
          </p>
          <Link to={Path.HOME} className="btn-blue">
            Back to Homepage
          </Link>
        </div>
      </div>
    </section>
  );
};
