import * as React from "react";
import { servicesList, useServices } from "./useServices";
import { getBoatServices } from "app/dictionaries/boatServices";
import { camelCase, kebabCase } from "lodash";
import { Link } from "react-router-dom";
import { Path } from "app/Path";

export const Services: React.FC = () => {
  const { onBoatServiceClick } = useServices();

  return (
    <div className="grid grid-cols-2 gap-2 md:gap-4 md:grid-cols-3 w-full lg:grid-cols-4">
      {servicesList.map((service, index) => {
        const { blue64Icon, name, iconAlt } = getBoatServices(
          camelCase(service)
        );
        return (
          <Link
            to={`${Path.BOATSERVICES}/${kebabCase(service)}?page=1`}
            className="bg-white p-2 cursor-pointer rounded border-2 border-transparent hover:border-blue-600"
            key={index}
            onClick={() => onBoatServiceClick(service)}
          >
            <img className="mb-2" src={blue64Icon} alt={iconAlt} />
            <p className="text-base font-semibold text-blue-900">{name}</p>
          </Link>
        );
      })}
    </div>
  );
};
