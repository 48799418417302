import * as React from "react";
import {
  MagnifyingGlassIcon,
  MapPinIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useSearchmechanics } from "./useSearchmechanics";
import classNames from "classnames";
import { MechanicAccountsProps } from "app/mechanic/mechanicProps";
import { Path } from "app/Path";
import { isMobile } from "react-device-detect";

export interface SearchMechanicsProps {
  searchedMechanics?: MechanicAccountsProps[];
}

export const SearchMechanics: React.FC<SearchMechanicsProps> = (props) => {
  const { searchedMechanics = [] } = props;
  const {
    searchedAddress,
    setSearchedAddress,
    handleOnChangeMechanicSearch,
    onSubmit,
    onInputFocus,
    inputValue,
    setInputValue,
    isInputFocused,
    showSearchDropdown,
    addressParam,
    searchedCoordinates,
  } = useSearchmechanics();

  return (
    <form className={"w-full relative"} onSubmit={onSubmit}>
      <label className="text-sm font-medium text-gray-900 sr-only dark:text-white">
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <MagnifyingGlassIcon
            className={classNames("h-6 w-6 mr-2", {
              "text-blue-600": isInputFocused,
              "text-gray-500": !isInputFocused,
            })}
          />
        </div>
        <input
          name="address"
          className={`block w-full py-5 md:py-7 px-10 ${
            window.location.pathname === Path.HOME ? "bg-white" : "bg-gray-50"
          } text-gray-900 border border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
          placeholder={
            addressParam || (searchedCoordinates.lat && searchedCoordinates.lng)
              ? "Map area"
              : "Enter Zipcode or location"
          }
          onChange={handleOnChangeMechanicSearch}
          onFocus={() => onInputFocus(true)}
          onBlur={() => onInputFocus(false)}
          value={inputValue}
          required
        />
        {inputValue && (
          <div
            className="absolute cursor-pointer inset-y-0 right-3 flex items-center pl-3"
            onClick={() => {
              setInputValue("");
              setSearchedAddress("");
            }}
          >
            <XCircleIcon className="w-6 h-6 text-gray-500 z-50" />
          </div>
        )}
      </div>
      {!!searchedAddress && (
        <button
          type="submit"
          className={classNames(
            "z-40 w-full absolute translate-y-2 bg-white border border-gray-200 rounded shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700",
            {
              visible: showSearchDropdown,
              invisible: !showSearchDropdown,
            }
          )}
        >
          <div className="flex p-4 hover:bg-gray-50">
            <MapPinIcon className="h-6 w-6 mr-2 text-gray-500" />
            <p className="font-normal text-gray-700 dark:text-gray-400">
              {searchedAddress}
            </p>
          </div>
        </button>
      )}
      {searchedMechanics.length === 0 && !addressParam && (
        <button
          type="submit"
          className="w-full mt-4 px-6 py-3.5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          Find mechanics near me
        </button>
      )}
    </form>
  );
};
