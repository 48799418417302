import * as React from "react";
import {
  MechanicAreasCategory,
  mechanicAreasList,
  useMechanicAreas,
} from "./useMechanicAreas";
import { Spinner } from "flowbite-react";
import { motion } from "framer-motion";
import classNames from "classnames";
import { listMotionContainer, listMotionItem } from "app/motion/listMotion";
import BlogThubnailPlaceholder from "app/assets/images/blogThubnailPlaceholder.jpeg";
import { camelCase, capitalize } from "lodash";
import { regionImages } from "app/dictionaries/regionImages";
import { GhoastDiv } from "./components/ghostDiv";

export interface MechanicAreasProps {
  category: MechanicAreasCategory;
}

export const MechanicAreas: React.FC<MechanicAreasProps> = (props) => {
  const { category } = props;
  const { mechanicAreas, locationSearch } = useMechanicAreas();

  return (
    <motion.ul
      className={classNames(
        "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-8 m-auto"
      )}
      variants={listMotionContainer}
      initial={!mechanicAreas ? "visible" : "hidden"}
      animate="visible"
    >
      {!mechanicAreas ? (
        <GhoastDiv />
      ) : (
        mechanicAreasList(category, mechanicAreas).map(
          (area: any, index: number) => (
            <motion.li
              variants={listMotionItem}
              className="relative bg-gradient-to-t from-blue-900 to-gray-200 overflow-hidden rounded border-2 border-white hover:border-blue-600 dark:bg-gray-800 dark:border-gray-700 hover:cursor-pointer"
              onClick={() => {
                locationSearch(`${area.region}, ${area.country}`);
              }}
              key={index}
            >
              <img
                className="md:h-56 w-full object-cover mix-blend-multiply"
                src={
                  regionImages[camelCase(area.region)] ||
                  BlogThubnailPlaceholder
                }
                alt={
                  regionImages[area.region]
                    ? `Skyline of ${area.region}`
                    : "The boat Aquila 28 Molokai Cuddy sailing on the water"
                }
              />
              <p className="absolute top-1/2 -translate-y-1/2 w-full text-center md:text-2xl font-semibold text-white">
                {capitalize(area.region)}
              </p>
            </motion.li>
          )
        )
      )}
    </motion.ul>
  );
};
