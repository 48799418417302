import * as React from "react";
import FmbmIconBlue from "app/assets/images/icons/fmbmIconBlue.svg";
import classNames from "classnames";
import { useFooter } from "./useFooter";
import {
  MechanicAreasCategory,
  mechanicAreasList,
} from "app/mechanicAreas/useMechanicAreas";
import { camelCase, startCase } from "lodash";
import { Spinner } from "flowbite-react";
import { Link } from "react-router-dom";
import { Path } from "app/Path";
import { servicesList } from "app/boatServices/services/useServices";
import { getBoatServices } from "app/dictionaries/boatServices";

export interface FooterProps {}

export const Footer: React.FC = () => {
  const { mechanicAreas, locationSearch } = useFooter();

  return (
    <footer className={classNames("w-full mt-16 bg-white dark:bg-gray-900")}>
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="https://flowbite.com/" className="flex items-center">
              <img
                src={FmbmIconBlue}
                className="h-8 mr-3"
                alt="Find my boat mechanic Logo"
              />
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 md:w-2/3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Boat Services
              </h2>
              <ul className="text-gray-500 flex flex-col gap-2">
                {servicesList.map((service, index) => {
                  const { name } = getBoatServices(camelCase(service));
                  return (
                    <Link
                      key={index}
                      to={`${Path.BOATSERVICES}/${service}?page=1`}
                      className="hover:underline"
                    >
                      <li>{name}</li>
                    </Link>
                  );
                })}
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Near Me
              </h2>
              <ul className="text-gray-500 flex flex-col gap-2">
                {mechanicAreas ? (
                  mechanicAreasList(
                    MechanicAreasCategory.NEARBY,
                    mechanicAreas
                  ).map((area: any, index: number) => (
                    <button
                      className="hover:underline hover:cursor-pointer"
                      onClick={() =>
                        locationSearch(`${area.region}, ${area.country}`)
                      }
                      key={index}
                    >
                      <li className="flex">{startCase(area.region)}</li>
                    </button>
                  ))
                ) : (
                  <Spinner size={"lg"} />
                )}
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Company
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 flex flex-col gap-2">
                <li>
                  <Link to={Path.PRIVACY} className="hover:underline">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to={Path.TAC} className="hover:underline">
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <a
                    href="mailto:al@tacit.ventures?subject=Find my boat mechanic"
                    className="hover:underline"
                  >
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 w-full sm:text-center dark:text-gray-400">
            © 2023{" "}
            <a
              href="https://findmyboatmechanic.com/"
              className="hover:underline"
            >
              Find my boat mechanic, Inc
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};
