import * as React from "react";
import { Dropdown, Avatar } from "flowbite-react";
import { Path } from "app/Path";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useNavbar } from "./useNavbar";
import classNames from "classnames";
import { titleize } from "app/utils/titleize";
import FmbmIconBlue from "app/assets/images/icons/fmbmIconBlue.svg";
import FmbmIconWhite from "app/assets/images/icons/fmbmIconWhite.svg";
import { isMobile, isMobileOnly } from "react-device-detect";

export interface NavProps {}

export const Nav: React.FC<NavProps> = () => {
  const {
    user: { id, avatar, email, firstName, lastName },
    openAuthModal,
    drawerIsOpen,
    setDrawerIsOpen,
    handleLogout,
    user,
    handleMobileLoginClick,
    visible,
  } = useNavbar();
  return (
    <div
      className={classNames(
        "flex sticky justify-between top-0 duration-500 z-40 p-4 w-full",
        {
          "top-0": visible,
          "top-[-100px]":
            !visible && window.location.pathname !== Path.MECHANICS,
          "p-3": isMobile,
          "bg-transparent":
            window.location.pathname === Path.HOME && window.scrollY < 60,
          "bg-white":
            window.scrollY > 60 || window.location.pathname !== Path.HOME,
        }
      )}
    >
      <div className="flex items-center gap-8">
        <Link to={Path.HOME} className="flex font-bold">
          {isMobileOnly &&
          window.location.pathname === Path.HOME &&
          window.scrollY < 60 ? (
            <img
              src={FmbmIconWhite}
              alt="Find my boat mechanic icon"
              className="mr-3 h-8"
            />
          ) : (
            <img
              src={FmbmIconBlue}
              alt="Find my boat mechanic icon"
              className="mr-3 h-8"
            />
          )}
        </Link>
        <ul className="hidden md:flex md:gap-4">
          <li>
            <Link
              to={Path.EXPLORE}
              className={classNames(
                "text-sm transition duration-150 border-b-2 font-bold hover:border-blue-500",
                {
                  "border-blue-500": window.location.pathname === Path.EXPLORE,
                },
                {
                  "border-transparent":
                    window.location.pathname !== Path.EXPLORE,
                }
              )}
            >
              Explore
            </Link>
          </li>
          <li>
            <Link
              to={Path.BLOG}
              className={classNames(
                "text-sm transition duration-150 border-b-2 font-bold hover:border-blue-500",
                { "border-blue-500": window.location.pathname === Path.BLOG },
                { "border-transparent": window.location.pathname !== Path.BLOG }
              )}
            >
              Resources
            </Link>
          </li>
        </ul>
      </div>

      <div className="hidden md:flex items-center gap-2">
        <ul className="mr-4">
          <li>
            <Link
              to={Path.MECHANICS_FAVORITES}
              className={classNames(
                "text-sm transition duration-150 border-b-2 font-bold hover:border-blue-500",
                {
                  "border-blue-500":
                    window.location.pathname === Path.MECHANICS_FAVORITES,
                },
                {
                  "border-transparent":
                    window.location.pathname !== Path.MECHANICS_FAVORITES,
                }
              )}
            >
              Favorites
            </Link>
          </li>
        </ul>
        {id ? (
          <Dropdown
            arrowIcon={false}
            inline={true}
            label={<Avatar alt="User avatar" img={avatar} rounded={true} />}
            className="!p-0"
          >
            <Dropdown.Item className="!p-0 font-medium">
              <Link to={Path.SETTINGS} className="w-full font-bold py-2 px-4">
                {email}
              </Link>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className="w-full py-2 px-4" onClick={handleLogout}>
              Sign out
            </Dropdown.Item>
          </Dropdown>
        ) : (
          <button className="btn-blue" onClick={openAuthModal}>
            Login
          </button>
        )}
      </div>
      <div
        className="flex cursor-pointer md:hidden items-center"
        onClick={() => setDrawerIsOpen(true)}
      >
        <Bars3Icon
          className={classNames("h-10 w-6", {
            "text-white":
              window.scrollY < 60 && window.location.pathname === Path.HOME,
          })}
        />
      </div>
      <div
        className={classNames(
          "fixed top-0 right-0 h-screen duration-500 px-4 py-14 overflow-y-auto transition-transform bg-white w-80 dark:bg-gray-800",
          {
            "transition-opacity opacity-100 translate-x-0": drawerIsOpen,
            "transition-all translate-x-full": !drawerIsOpen,
          }
        )}
      >
        <button
          type="button"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={() => setDrawerIsOpen(false)}
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        {user.id ? (
          <>
            <div className="flex flex-col gap-4">
              <Avatar alt="User settings" img={avatar} rounded={true} />
              <p className="text-center">
                {`${titleize(firstName)} ${titleize(lastName)}`}
              </p>
              <p className="text-sm text-center text-gray-500">{email}</p>
              <div className="flex flex-col items-start gap-4">
                <Link
                  className="font-bold"
                  to={Path.SETTINGS}
                  onClick={() => setDrawerIsOpen(false)}
                >
                  My Profile
                </Link>
                <Link
                  to={Path.MECHANICS_FAVORITES}
                  onClick={() => setDrawerIsOpen(false)}
                  className={classNames(
                    "transition duration-150 border-b-2 font-bold hover:border-blue-500",
                    {
                      "border-blue-500":
                        window.location.pathname === Path.MECHANICS_FAVORITES,
                    },
                    {
                      "border-transparent":
                        window.location.pathname !== Path.MECHANICS_FAVORITES,
                    }
                  )}
                >
                  Favorites
                </Link>
                <button onClick={handleLogout}>Sign out</button>
              </div>
            </div>
            <Link
              to={Path.EXPLORE}
              className={classNames(
                "text-sm transition duration-150 border-b-2 font-bold hover:border-blue-500",
                {
                  "border-blue-500": window.location.pathname === Path.EXPLORE,
                },
                {
                  "border-transparent":
                    window.location.pathname !== Path.EXPLORE,
                }
              )}
            >
              Explore
            </Link>
            <Link
              to={Path.BLOG}
              className={classNames(
                "text-sm transition duration-150 border-b-2 font-bold hover:border-blue-500",
                { "border-blue-500": window.location.pathname === Path.BLOG },
                {
                  "border-transparent": window.location.pathname !== Path.BLOG,
                }
              )}
            >
              Resources
            </Link>
          </>
        ) : (
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4 items-center">
              <Avatar alt="User settings" rounded={true} />
              <button
                onClick={handleMobileLoginClick}
                className="btn-blue w-full"
              >
                Login
              </button>
            </div>
            <Link to={Path.BLOG} onClick={() => setDrawerIsOpen(false)}>
              <span className="text-md transition duration-150 border-b-2 font-bold border-transparent hover:border-blue-500">
                Resources
              </span>
            </Link>
            <Link to={Path.EXPLORE} onClick={() => setDrawerIsOpen(false)}>
              <span className="text-md transition duration-150 border-b-2 font-bold border-transparent hover:border-blue-500">
                Explore
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
