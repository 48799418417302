import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Router } from "app/routes/router";
import { Nav } from "./components/navbar/Navbar";
import { AuthModal } from "./components/auth/authModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useApp } from "./useApp";
import { Rollbar } from "./rollbar/Rollbar";
import { Helmet } from "react-helmet";

function App() {
  useApp();
  return (
    <div className="App">
      <Helmet>
        <title>Find My Boat Mechanic | Trusted Boat Mechanics Near You</title>
        <meta
          name="description"
          content="Discover reliable and experienced boat mechanics in your area with FindMyBoatMechanic.com. Get your boat serviced by trusted experts today. Search now!"
        />
        <meta
          property="og:description"
          content="Discover reliable and experienced boat mechanics in your area with FindMyBoatMechanic.com. Get your boat serviced by trusted experts today. Search now!"
        />
        <meta
          property="og:title"
          content="Find My Boat Mechanic | Trusted Boat Mechanics Near You"
        />
        <meta
          property="og:image"
          content="https://bikini-bottom-public.s3.us-east-2.amazonaws.com/fmbm+open+graph.png"
        />
        <meta
          property="og:image:alt"
          content="A copy saying Find a trusted boat mechanic near you, with a young mechanic in the background."
        />
      </Helmet>
      <Rollbar>
        <BrowserRouter>
          <Nav />
          <AuthModal />
          <Router />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </BrowserRouter>
      </Rollbar>
    </div>
  );
}

export default App;
