import { MechanicAreas } from "app/mechanicAreas/mechanicAreas";
import { MechanicAreasCategory } from "app/mechanicAreas/useMechanicAreas";
import { Footer } from "app/components/footer/footer";
import * as React from "react";
import { Helmet } from "react-helmet";

export interface ExploreProps {}

export const Explore: React.FC<ExploreProps> = () => {
  return (
    <>
      <Helmet>
        <title>{"Boat mechanics nearby"}</title>
        <meta
          name="description"
          content={
            "A list of areas nearby that have boat mechanics provided by findmyboatmechanic.com"
          }
        />
        <meta property="og:title" content={"Boat mechanics nearby"} />
        <meta
          property="og:description"
          content={
            "A list of areas nearby that have boat mechanics provided by findmyboatmechanic.com"
          }
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <section>
        <div className="px-4 mx-auto max-w-screen-xl text-center py-12">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none  md:text-5xl lg:text-6xl">
            Your Trusted Boat Mechanic Locator
          </h1>
          <p className="mb-8 text-lg font-normal  lg:text-xl sm:px-16">
            Connecting You to Reliable Boat Mechanics in Major Areas near you
          </p>
        </div>
      </section>
      <div className="max-w-7xl m-auto rounded md:bg-white md:p-6">
        <MechanicAreas category={MechanicAreasCategory.NEARBY} />
      </div>
      <Footer />
    </>
  );
};
