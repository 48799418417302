import { toast as ReactToast } from "react-toastify";

export enum ToastType {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
}

export const toast = (type: ToastType, message: string) =>
  ReactToast[type](message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
