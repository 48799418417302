import * as React from "react";
import clipboardListSvg from "app/assets/images/icons/clipboardList.svg";
import classNames from "classnames";

export interface ListItemProps {
  icon?: any;
  content?: string;
  customContent?: any;
  listGap?: string;
  hideIcon?: boolean;
  removeListGap?: boolean;
  center?: boolean;
  bold?: boolean;
  iconContentGap?: string;
}

export const ListItem: React.FC<ListItemProps> = (props) => {
  const {
    icon,
    content,
    customContent,
    listGap = "mb-6",
    hideIcon = false,
    removeListGap = false,
    center = false,
    bold = false,
    iconContentGap = "pr-5",
  } = props;
  return (
    <div
      className={classNames("flex min-w-0", {
        [listGap]: !removeListGap,
        "items-center": center,
      })}
    >
      <div
        className={classNames("", {
          [iconContentGap]: iconContentGap,
        })}
      >
        {(!hideIcon && icon) ||
          (!hideIcon && <img src={clipboardListSvg} alt={`${content}-icon`} />)}
      </div>
      {customContent ? (
        <div>{customContent}</div>
      ) : (
        <p
          className={classNames(
            "text-sm text-gray-900 truncate dark:text-white",
            {
              "font-medium": bold,
            }
          )}
        >
          {content}
        </p>
      )}
    </div>
  );
};
