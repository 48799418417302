import { googleLogout } from "@react-oauth/google";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import http, { cookieTokenName } from "app/requests/axiosInstance";
import Cookies from "js-cookie";
import { getBase64FromUrl } from "app/utils/getBase64";

interface UsersState {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  avatar?: string;
  createdAt: string;
  updatedAt: string;
}

// Define the initial state using that type
const initialState = {} as UsersState;

export enum LOCAL_STORAGE {
  FMBM_USER = "fmbmUser",
}

const handleAuthIn = ({
  jwtAuthToken,
  user,
}: {
  jwtAuthToken: string;
  user: any;
}) => {
  const { email, id } = user;
  Cookies.set(cookieTokenName!, jwtAuthToken);
  localStorage.setItem(
    LOCAL_STORAGE.FMBM_USER,
    JSON.stringify({
      id,
      email,
    })
  );
};

const handleAuthOut = () => {
  Cookies.remove(cookieTokenName!);
  localStorage.removeItem(LOCAL_STORAGE.FMBM_USER);
};

export const userLogin = createAsyncThunk(
  "users/loginStatus",
  async ({ email, password }: any, thunkAPI) => {
    try {
      const response = await http.post("/users/sign_in", {
        user: {
          email: email,
          password: password,
        },
      });
      handleAuthIn({
        jwtAuthToken: response.headers.authorization.split(" ")[1],
        user: response.data,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const facebookOauth = createAsyncThunk(
  "users/facebookOauthStatus",
  async ({ email, first_name, last_name, picture }: any, thunkAPI) => {
    try {
      const response = await http.post("/v1/users/facebook_oauth", {
        user: {
          email: email,
          firstName: first_name,
          lastName: last_name,
          avatar: picture.data.url,
          clientId: process.env.REACT_APP_FACEBOOK_OAUTH_CLIENT_ID,
          accountType: "CustomerAccount",
        },
      });
      handleAuthIn({
        jwtAuthToken: response.headers.authorization.split(" ")[1],
        user: response.data,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const googleOauth = createAsyncThunk(
  "users/googleOauthStatus",
  async ({ given_name, family_name, picture, email, role }: any, thunkAPI) => {
    try {
      let formData = new FormData() as any;
      formData.avatar = { data: await getBase64FromUrl(picture) };
      const response = await http.post("/v1/users/google_oauth", {
        user: {
          email: email,
          firstName: given_name,
          lastName: family_name,
          avatar: formData.avatar,
          accountType: "CustomerAccount",
          role,
        },
      });
      handleAuthIn({
        jwtAuthToken: response.headers.authorization.split(" ")[1],
        user: response.data,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userLogout = createAsyncThunk(
  "users/logoutStatus",
  async ({}: any, thunkAPI) => {
    try {
      googleLogout();
      const response = await http.delete("/users/sign_out");
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    } finally {
      handleAuthOut();
    }
  }
);

export const userSignup = createAsyncThunk(
  "users/signupStatus",
  async ({ email, password, accountType, role, firstName }: any, thunkAPI) => {
    try {
      const response = await http.post("/users", {
        user: {
          email: email,
          password: password,
          account_type: accountType,
          role: role,
          first_name: firstName,
        },
      });
      handleAuthIn({
        jwtAuthToken: response.headers.authorization.split(" ")[1],
        user: response.data,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userFromCookie = createAsyncThunk(
  "users/fromCookieStatus",
  async ({}: any, thunkAPI) => {
    try {
      const { data } = await http.get("/v1/users/me");
      return data;
    } catch (error: any) {
      thunkAPI.dispatch(userLogout({}) as any).unwrap();
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userUpdate = createAsyncThunk(
  "users/updateStatus",
  async (user: any, thunkAPI) => {
    try {
      const { data } = await http.put("/v1/users/update_preferences", {
        user,
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const passwordChange = createAsyncThunk(
  "users/passwordChangeStatus",
  async (
    { currentPassword, newPassword, passwordConfirmation }: any,
    thunkAPI
  ) => {
    try {
      const { data } = await http.post("/v1/users/change_password", {
        user: {
          currentPassword: currentPassword,
          newPassword: newPassword,
          passwordConfirmation: passwordConfirmation,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(userLogin.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
    builder.addCase(googleOauth.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
    builder.addCase(userSignup.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
    builder.addCase(userFromCookie.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
    builder.addCase(userUpdate.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
    builder.addCase(userLogout.fulfilled, (state: any, action) => {
      const newState = {} as UsersState;
      return newState;
    });
  },
});

export const selectUser = (state: RootState) => state.user;

export default usersSlice.reducer;
