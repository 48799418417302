import { HeartIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import * as React from "react";

export interface FavoriteHeartProps {
  isFavorited?: boolean;
  white?: boolean;
  onUnfavoriteClick: (event?: any) => void;
  onFavoriteClick: (event?: any) => void;
}

export const FavoriteHeart: React.FC<FavoriteHeartProps> = (props) => {
  const {
    isFavorited = false,
    white = false,
    onFavoriteClick,
    onUnfavoriteClick,
  } = props;

  return (
    <HeartIcon
      className={classNames(
        "h-[24px] w-[24px] stroke-2 cursor-pointer text-gray-900",
        {
          "!stroke-0 fill-red-500": isFavorited,
          "stroke-white": white,
        }
      )}
      onClick={isFavorited ? onUnfavoriteClick : onFavoriteClick}
    />
  );
};
