import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "app/store";
import http from "app/requests/axiosInstance";

// Define the initial state using that type
const initialState = null as any;

export const getIpData = createAsyncThunk("ip/data", async (_, thunkAPI) => {
  try {
    const { data } = await http.get(
      `https://api.ipdata.co?api-key=${process.env.REACT_APP_IPDATA_APIKEY!}`
    );
    return data;
  } catch (error) {
    thunkAPI.rejectWithValue(error);
  }
});

export const ipDataSlice = createSlice({
  name: "ipData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getIpData.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
  },
});

export const selectIpData = (state: RootState) => state.ipData;

export default ipDataSlice.reducer;
