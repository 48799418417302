import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import {
  getSearchedMechanics,
  selectSearchMechanics,
} from "app/redux/searchMechanicsSlice";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Path } from "app/Path";
import { selectIpData } from "app/redux/ipDataSlice";
import { locationSearchTrack } from "app/utils/track/track";

export const useHome = () => {
  const ipData = useSelector(selectIpData);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const searchedMechanics = useSelector(selectSearchMechanics).mechanicAccounts;

  const onExploreMechanicsClick = () => {
    navigate(
      `${Path.MECHANICS}?${createSearchParams({
        address: `${ipData.postal}, ${ipData.city}, ${ipData.region}, ${ipData.countryName}`,
        lat: ipData.latitude,
        lng: ipData.longitude,
      })}`
    );

    locationSearchTrack({
      city: ipData.city,
      postalCode: ipData.postal,
      region: ipData.region,
      country: ipData.countryName,
      coordinates: {
        lat: ipData.latitude,
        lng: ipData.longitude,
      },
    });
  };

  useEffect(() => {
    (async () => {
      try {
        await dispatch(
          getSearchedMechanics({
            searchQuery: `${ipData.postal}, ${ipData.city}, ${ipData.region}, ${ipData.country_name}`,
            searchType: "address",
          })
        ).unwrap();
      } catch {}
    })();
  }, [ipData]);

  return {
    ipData,
    searchedMechanics,
    onExploreMechanicsClick,
  };
};
