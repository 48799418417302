import * as React from "react";

export interface ReviewsProps {
  mechanicAccount: any;
}

export const Reviews: React.FC<ReviewsProps> = (props) => {
  const { mechanicAccount } = props;
  return (
    <div className="p-4 bg-white rounded border border-gray-200 flex-col justify-start items-start gap-6 inline-flex">
      <div className="flex-col justify-start items-start gap-8 flex">
        <div className=" text-gray-900 text-xl font-medium">
          People are saying
        </div>
      </div>
      {mechanicAccount.reviews.map((review: any) => (
        <div
          key={review.id}
          className="bg-gray-50 rounded flex flex-col justify-start items-start p-2"
        >
          <div className="self-stretch text-black text-base font-medium pb-4">
            "{review.content}"
          </div>
          <div className="self-stretch flex-col justify-start items-end gap-1 flex">
            <div className="text-black text-base font-normal">
              {review.reviewerName}
            </div>
            <div className="text-gray-900 text-base font-normal italic">
              {review.source}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
