export enum Path {
  HOME = "/",
  LOGIN = "/login",
  MECHANICS = "/mechanics",
  MECHANICS_FAVORITES = "/mechanics/favorites",
  BOATSERVICES = "/boat-services",
  SETTINGS = "/settings",
  BLOG = "/blog",
  EXPLORE = "/explore",
  TAC = "/terms-and-conditions",
  PRIVACY = "/privacy-policy",
}
