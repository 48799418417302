import { userIntentionInfo, userIntentionTrack } from "app/utils/track/track";

export const useContact = () => {
  const trackIntention = (info: userIntentionInfo) => {
    userIntentionTrack(info);
  };
  return {
    trackIntention,
  };
};
