declare global {
  interface Window {
    analytics: any;
  }
}

export enum TrackEvents {
  SEARCH = "search",
  BLOGVIEWED = "blog viewed",
  MECHANICVIEWED = "mechanic viewed",
  NEWSIGNUP = "new signup",
  LOGIN = "login",
  FAVORITEDMECHANIC = "favorited mechanic",
  UNFAVORITEDMECHANIC = "unfavorited mechanic",
  BOATSERVICES = "boat services",
  USER_INTENTION = "user intention",
}

export interface locationSearchInfo {
  city?: string;
  postalCode?: string;
  region: string;
  country: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  service?: string;
}

export interface blogViewInfo {
  id: string;
  title: string;
  slug: string;
}

export interface mechanicViewInfo {
  id: string;
  name: string;
  address: locationSearchInfo;
}

export interface newSignupInfo {
  id: number;
  email: string;
  firstName?: string;
  lastName?: string;
  role?: string;
}

export interface loginInfo {
  id: number;
  email: string;
}

export interface favoriteInfo {
  id: number;
  name: string;
  address?: locationSearchInfo;
}

export interface boatServiceInfo {
  service: string;
}

export interface userIntentionInfo {
  type: string;
  value: string;
}

export const locationSearchTrack = (info: locationSearchInfo) => {
  track(TrackEvents.SEARCH, info);
};

export const blogViewTrack = (info: blogViewInfo) => {
  track(TrackEvents.BLOGVIEWED, info);
};

export const mechanicViewTrack = (info: mechanicViewInfo) => {
  track(TrackEvents.MECHANICVIEWED, info);
};

export const newSignupTrack = (info: newSignupInfo) => {
  window.analytics.identify(info.id, { email: info.email });
  track(TrackEvents.NEWSIGNUP, info);
};

export const loginTrack = (info: loginInfo) => {
  window.analytics.identify(info.id, { email: info.email });
  track(TrackEvents.LOGIN, info);
};

export const favoritedMechanicTrack = (info: favoriteInfo) => {
  track(TrackEvents.FAVORITEDMECHANIC, info);
};

export const unfavoritedMechanicTrack = (info: favoriteInfo) => {
  track(TrackEvents.UNFAVORITEDMECHANIC, info);
};

export const boatServiceTrack = (info: boatServiceInfo) => {
  track(TrackEvents.BOATSERVICES, info);
};

export const userIntentionTrack = (info: userIntentionInfo) => {
  track(TrackEvents.USER_INTENTION, info);
};

const track = (event: TrackEvents, info: any) => {
  if (process.env.NODE_ENV === "production") {
    window.analytics.track(event, info);
  }
};
