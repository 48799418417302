import http from "app/requests/axiosInstance";
import { blogViewTrack } from "app/utils/track/track";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const useBlog = () => {
  let { slug } = useParams();
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data }: any = await http.get(`/v1/blogs/${slug}`, {});

        if (!data) {
          return navigate("/404");
        }

        setBlogData(data);

        blogViewTrack({
          id: data.id,
          title: data.title,
          slug: data.slug,
        });
      } catch (error: any) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const fwm = document.querySelector('[data-f-id="pbf"]');
    if (fwm) {
      fwm.remove();
    }
  }, []);

  return {
    blogData,
    loading,
  };
};
