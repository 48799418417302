import { useEffect, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSelectedMechanic,
  selectSelectedMechanic,
} from "app/redux/selectedMechanicSlice";
import { AppDispatch } from "app/store";
import { toast, ToastType } from "app/utils/toast";
import {
  addMechanicFavorite,
  removeMechanicFavorite,
  selectMechanicFavorites,
} from "app/redux/mechanicFavoritesSlice";
import { find, kebabCase, random, startCase } from "lodash";
import { getFormValues } from "app/utils/getFormValues";
import { createMechanicFavoriteList } from "app/redux/mechanicFavoritesListSlice";
import { selectUser } from "app/redux/usersSlice";
import { useQuery } from "app/utils/useQuery";
import {
  favoritedMechanicTrack,
  mechanicViewTrack,
} from "app/utils/track/track";

const isCurrentFavorited = (favoriteMechanics: any, mechanicId: any) => {
  return find(
    favoriteMechanics,
    (fav) => fav.favoritable.id === Number(mechanicId)
  );
};

export const useMechanicShow = () => {
  let { id, country, region, name } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const query = useQuery();
  const navigate = useNavigate();
  const mechanicAccount = useSelector(selectSelectedMechanic);
  const favoriteMechanics = useSelector(selectMechanicFavorites);
  const currentFavorite = isCurrentFavorited(favoriteMechanics, id);
  const [isFavoriteModalOpen, setIsFavoriteModalOpen] = useState(false);
  const user = useSelector(selectUser);
  const noServices =
    mechanicAccount?.providedServices?.length === 0 &&
    mechanicAccount?.serviceableBrands?.length === 0 &&
    mechanicAccount?.serviceableModels?.length === 0;
  const bannerId = useRef(random(1, 10));

  const meta = {
    title: `${mechanicAccount.name} - Expert Boat Mechanic in ${startCase(
      region
    )}, ${startCase(country)} | FindMyBoatMechanic.com`,
    description: `${mechanicAccount.name} in ${startCase(region)}, ${startCase(
      country
    )}, offers top-notch boat mechanic services, including repair, maintenance, and diagnostics. Trust our experienced team to keep your vessel in peak condition. Visit FindMyBoatMechanic.com to learn more!`,
    meta: {
      charset: "utf-8",
      name: {
        keywords: `${mechanicAccount.name}, ${startCase(region)}, ${startCase(
          country
        )} boat mechanic, boat repair, boat maintenance, marine mechanic, boat diagnostics, boat service, FindMyBoatMechanic.com`,
      },
    },
  };

  const handleFavoriteClick = () => {
    if (!user.id) {
      toast(ToastType.INFO, "You must be logged in to add favorites.");
      query.append("auth", "login");
      navigate({
        pathname: window.location.pathname,
        search: query.toString(),
      });
      return;
    } else {
      setIsFavoriteModalOpen(true);
    }
  };

  const handleFavoriteListClick = (favoriteList: any) => {
    onFavoriteSubmit({
      favoritor: favoriteList,
      favoritable: mechanicAccount,
    });
  };

  const onFavoriteSubmit = async ({ favoritor, favoritable }: any) => {
    try {
      await dispatch(
        addMechanicFavorite({
          favoritorId: favoritor.id,
          favoritableId: favoritable.id,
        }) as any
      ).unwrap();
      setIsFavoriteModalOpen(false);
      toast(ToastType.SUCCESS, `Mechanic added to ${favoritor.name} list`);

      favoritedMechanicTrack({
        id: favoritable.id,
        name: favoritable.name,
        address: {
          city: favoritable.addresses[0].city,
          region: favoritable.addresses[0].region,
          country: favoritable.addresses[0].country,
          postalCode: favoritable.addresses[0].postalCode,
          coordinates: {
            lat: favoritable.addresses[0].latitude,
            lng: favoritable.addresses[0].longitude,
          },
        },
      });
    } catch (error: any) {
      console.log(error);
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong with adding favorite"
      );
    }
  };

  const onUnfavoriteClick = async (favoriteId: any) => {
    try {
      await dispatch(removeMechanicFavorite(favoriteId) as any).unwrap();
      toast(
        ToastType.SUCCESS,
        `Mechanic has been remove from your favorite list`
      );
    } catch (error: any) {
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong with removing favorite"
      );
    }
  };

  const onFavoriteWithNewList = async (event: any) => {
    event.preventDefault();
    const { newListName } = getFormValues(event.target);
    try {
      await batch(async () => {
        const newCreateList = await dispatch(
          createMechanicFavoriteList({
            name: newListName,
          }) as any
        ).unwrap();

        dispatch(
          addMechanicFavorite({
            favoritorId: newCreateList.id,
            favoritableId: mechanicAccount.id,
          }) as any
        ).unwrap();
      });
      setIsFavoriteModalOpen(false);
      toast(ToastType.SUCCESS, `Mechanic added to ${newListName} list`);
      favoritedMechanicTrack({
        id: mechanicAccount.id!,
        name: mechanicAccount.name,
        address: {
          city: mechanicAccount.addresses[0].city,
          region: mechanicAccount.addresses[0].region,
          country: mechanicAccount.addresses[0].country,
          postalCode: mechanicAccount.addresses[0].postalCode,
          coordinates: {
            lat: mechanicAccount.addresses[0].latitude,
            lng: mechanicAccount.addresses[0].longitude,
          },
        },
      });
    } catch (error: any) {
      console.log(error);
      toast(
        ToastType.ERROR,
        error?.data?.error || "Something went wrong with adding favorite"
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const potentialMechanic = await dispatch(
          getSelectedMechanic(Number(id))
        ).unwrap();
        if (
          !potentialMechanic ||
          kebabCase(potentialMechanic.addresses[0].country) !== country ||
          kebabCase(potentialMechanic.addresses[0].region) !== region ||
          kebabCase(potentialMechanic.name) !== name
        ) {
          return navigate("/404");
        }

        mechanicViewTrack({
          id: potentialMechanic.id,
          name: potentialMechanic.name,
          address: {
            city: potentialMechanic.addresses[0].city,
            region: potentialMechanic.addresses[0].region,
            country: potentialMechanic.addresses[0].country,
            postalCode: potentialMechanic.addresses[0].postalCode,
            coordinates: {
              lat: potentialMechanic.addresses[0].latitude,
              lng: potentialMechanic.addresses[0].longitude,
            },
          },
        });
      } catch (error: any) {
        toast(
          ToastType.ERROR,
          error?.data?.error || "Something went wrong getting mechanic"
        );
        console.log(error);
      }
    })();
  }, []);

  return {
    mechanicAccount,
    currentFavorite,
    favoriteMechanics,
    onUnfavoriteClick,
    isFavoriteModalOpen,
    setIsFavoriteModalOpen,
    handleFavoriteListClick,
    onFavoriteWithNewList,
    handleFavoriteClick,
    noServices,
    meta,
    bannerId,
  };
};
