import { boatServiceTrack, userIntentionTrack } from "app/utils/track/track";

export const servicesList = [
  "generalMaintenance",
  "motorServices",
  "electronicRepair",
  "controlSystems",
  "surveying",
  "hull&Fiberglass",
  "bodyWork",
  "upholstery&Canvas",
  "fluidSystems",
  "partsRequest",
  "springCommissioning",
  "winterization",
];

export const useServices = () => {
  const onBoatServiceClick = (service: string) => {
    window.scrollTo(0, 0);

    userIntentionTrack({ type: "service", value: service });

    boatServiceTrack({ service });
  };
  return { onBoatServiceClick };
};
